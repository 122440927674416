import React, { useState } from 'react'
import { Form, Modal, Button } from "react-bootstrap"

const ChangeCredential = (props) => {

  const [email, setEmail] = useState(props.user ? props.user.email : '')
  const [password, setPassword] = useState('')
  const [error, setError] = useState({})

  const handleSubmit = () => {
    if( !email ){
      setError({ email: 'Email should not left empty.' })
    }else if( !password ){
      setError({ password: 'Password should not left empty.'})
    }else {
      props.setOpen(false)
      props.changeUserCredential({
        uid: props.user.uid,
        email: email,
        password: password
      })
    }
  }

  return(
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Change Credential</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter your email."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error.email && <Form.Text>{error.email}</Form.Text>}
        </Form.Group>
        <Form.Group>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Please enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error.password && <Form.Text>{error.password}</Form.Text>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button disabled={ !email || !password } variant="outline-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeCredential;
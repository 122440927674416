import React, { useState, useEffect } from "react"
import MobileBanner from "./MobileBanner/index"
import Promotion from "./Promotion/index"
import PublicBanner from "./PublicBanner/index"
import Voucher from "./Voucher/index"
import Overview from "./Overview"
import SettingHOC from "./action"
import Tabs from "components/tab"
import Title from "components/title/title"
import "./index.css"

const Settings = (props) => {
  const [ selectedItem, setSelectedItem ] = useState('Promotion')
  
  useEffect(()=>{
    props.getPromotion()
    props.getMobileBanners()
    props.getPublicBanners()
    props.getVouchers()
    props.getOverview()
  }, [])

  return (
    <div className="card m-3">
      <Title
        header="Setting"
        subheader="Manage all the banner and promotion poster."
      />
      <Tabs
        selections={ [ 'Promotion', 'Public Banner', 'Mobile Banner', 'Voucher', 'Overview' ] }
        selectedItem={ selectedItem }
        onSelect={ setSelectedItem }
      />
      {
        selectedItem === 'Promotion' && <Promotion 
        promotion={ props.promotion }
        createPromotion={ props.createPromotion }
        updatePromotion={ props.updatePromotion }
        deletePromotion={ props.deletePromotion }/>
      }
      {
        selectedItem === 'Public Banner' && <PublicBanner 
        publicBanners={ props.publicBanners }
        createPublicBanners={ props.createPublicBanners }
        updatePublicBanner={ props.updatePublicBanner }
        deletePublicBanners={ props.deletePublicBanners } />
      }
      {
        selectedItem === 'Mobile Banner' && <MobileBanner
        mobileBanners={ props.mobileBanners } 
        createMobileBanners={ props.createMobileBanners }
        updateMobileBanner={ props.updateMobileBanner }
        deleteMobileBanners={ props.deleteMobileBanners }/>
      }
      {
        selectedItem === 'Voucher' && <Voucher 
        createVouchers={ props.createVouchers }
        updateVoucher={ props.updateVoucher }
        deleteVouchers={ props.deleteVouchers }
        vouchers={ props.vouchers } />
      }
      {
        selectedItem === 'Overview' && <Overview 
        overview={ props.overview }
        updateOverview={ props.updateOverview }
        />
      }
    </div>
  )
}

export default SettingHOC(Settings)

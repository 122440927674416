import React from "react"
import { Doughnut } from "react-chartjs-2"

const data = {
  labels: [
    "Durian Musang King",
    "BlackThorn",
    "Cempedak",
    "Red Dates",
    "Other",
  ],
  datasets: [
    {
      label: "Quantity",
      data: [180, 150, 100, 93, 125],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 3,
    },
  ],
}

const ProductSales = () => {
  return (
    <div className="card p-3" style={{ height: '97%' }}>
      <div className="card-title">Product Sales</div>
      <div className="d-flex">
        <div style={{ width: '50%', margin: 'auto', alignItems: 'center' }}>
          <Doughnut
            data={data}
            options={{
              responsive: true,
              layout: {
                padding: "10",
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div style={{ margin: 'auto' }}>
          {data.labels.map((label, labelIndex) => {
            return (
              <div className="d-flex my-1 align-items-center">
                <div
                  style={{
                    width: "40px",
                    height: "15px",
                    marginRight: "5px",
                    background: data.datasets[0].backgroundColor[labelIndex],
                    border: `1px solid ${data.datasets[0].borderColor[labelIndex]}`,
                  }}
                />
                <div style={{ fontSize: "12px" }}>{label}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default ProductSales

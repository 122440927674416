import { Button } from "react-bootstrap"
import { IoBrushOutline, IoTrashOutline } from "react-icons/io5"
import { FaUsersCog } from 'react-icons/fa'

import { SelectColumnFilter } from "utils/selectorFilter"

export const header = [
  {
    Header: "Name",
    accessor: "username",
    style: { width: '10%' },
    filterProps: "username",
    sort: true
  },
  {
    Header: "Email",
    accessor: "email",
    style: { width: '5%' },
    filterProps: "email",
    sort: true
  },
  {
    Header: "Position",
    accessor: "position",
    Filter: SelectColumnFilter,
    filter: "includes",
    style: { width: '5%' }
  },
  {
    Header: "Address",
    accessor: "address",
    style: { width: '30%' }
  },
  {
    Header: "Mobile No.",
    accessor: "whatsapp",
    style: { width: '10%' }
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "equals",
    style: { width: '10%' },
    Cell: ({ cell }) => {
      let status = cell.row.values.status
      return (
        <span
          style={{
            color:
              status === "active"
                ? "#42ba96"
                : status === "revoked"
                ? "#df4759"
                : "	#ffc107",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {cell.row.values.status === 'active' ? 'Active' : 'Inactive' }
        </span>
      )
    },
  },
  {
    Header: "Action",
    style: { width: '15%' },
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>
        <Button
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedDistributor(props.cell.row.original)
            props.setOpen(true)
          }}
        >
          <IoBrushOutline />
        </Button>
        {props.selectedItem === "Distributor" && <Button
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedDistributor(props.cell.row.original)
            props.setAssignModalOpen(true) 
          }}
        >
          <FaUsersCog />
        </Button>}
        <Button
          variant="danger"
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedDistributor(props.cell.row.original)
            props.setConfirmationText(
              "Are you sure to delete this client?"
            )
            props.setConfirmationModalOpen(true)
          }}
        >
          <IoTrashOutline />
        </Button>
      </div>
    ),
  },
]

export const createField = [
  {
    type: 'text',
    label: 'Name',
    placeholder: 'Please enter the name',
    value: 'username',
  },
  {
    type: 'text',
    label: 'Email',
    placeholder: 'Please enter the email',
    value: 'email',
  },
  {
    type: 'password',
    label: 'Password',
    placeholder: 'Please enter the password',
    value: 'password',
  },
  {
    type: 'password',
    label: 'Re-Enter Password',
    placeholder: 'Please re-enter the password',
    value: 'password2',
  },
  {
    type: 'location',
    label: 'Address',
    placeholder: 'Please enter the address',
    value: 'address',
  },
  {
    type: 'text',
    label: 'Worker ID/Licence',
    placeholder: 'Please enter the licence',
    value: 'worker_id',
  },
  {
    type: 'text',
    label: 'Mobile Number/Whatsapp',
    placeholder: 'Please enter the mobile number',
    value: 'whatsapp',
  },
  {
    type: 'select',
    label: 'Role',
    placeholder: 'Role Selection',
    value: 'role',
    options: [
      { value: '', label: 'Please select a role' },
      { value: 'Super Admin', label: 'Super Admin' },
      { value: 'Admin', label: 'Admin' },
      { value: 'Distributor', label: 'Distributor' },
      { value: 'Retailer', label: 'Retailer' },
      { value: 'Branch', label: 'Branch' },
    ]
  },
  {
    type: 'select',
    label: 'Position',
    placeholder: 'Position Selection',
    value: 'position',
    options: [
      { value: '', label: 'Please select a position' },
      { value: 'Super Leader', label: 'Super Leader' },
      { value: 'Leader', label: 'Leader' },
      { value: 'Agent', label: 'Agent' },

    ]
  },
]

export const updateField = [
  {
    type: 'text',
    label: 'Name',
    placeholder: 'Please enter the name',
    value: 'username',
  },
  {
    type: 'location',
    label: 'Address',
    placeholder: 'Please enter the address',
    value: 'address',
  },
  {
    type: 'text',
    label: 'Worker ID/Licence',
    placeholder: 'Please enter the licence',
    value: 'worker_id',
  },
  {
    type: 'text',
    label: 'Mobile Number/Whatsapp',
    placeholder: 'Please enter the mobile number',
    value: 'whatsapp',
  },
  {
    type: 'select',
    label: 'Role',
    placeholder: 'Role Selection',
    value: 'role',
    options: [
      { value: '', label: 'Please select a role' },
      { value: 'Super Admin', label: 'Super Admin' },
      { value: 'Admin', label: 'Admin' },
      { value: 'Retailer', label: 'Retailer' },
      { value: 'Distributor', label: 'Distributor' },
      { value: 'Branch', label: 'Branch' },
    ]
  },
  {
    type: 'select',
    label: 'Position',
    placeholder: 'Position Selection',
    value: 'position',
    options: [
      { value: '', label: 'Please select a position' },
      { value: 'Super Leader', label: 'Super Leader' },
      { value: 'Leader', label: 'Leader' },
      { value: 'Agent', label: 'Agent' },
    ]
  },
  {
    type: 'select',
    label: 'Status',
    placeholder: 'Status Selection',
    value: 'status',
    options: [
      { value: '', label: 'Please select a status' },
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ]
  },
]

export const distributor = {
  username:"",
  email:"",
  password: "",
  password2: "",
  position:"",
  role: "",
  address:"",
  location: {
    latitude: '',
    longitude: ''
  },
  worker_id: "",
  whatsapp: "",
  level: "bronze",
  status:"active",
}
import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Form, Modal, Button } from "react-bootstrap"

const Create = (props) => {
  const [data, setData] = useState({
    status: "active",
    category: "",
    promotion_description: "",
    image: "",
    promotion_title: "",
    image_file_name: "",
  })
  const [error, setError] = useState({})
  const [preview, setPreview] = useState("")

  useEffect(() => {
    if (props.isOpen) {
      setData({
        status: "active",
        category: "",
        promotion_description: "",
        image: "",
        promotion_title: "",
        image_file_name: "",
      })
      setPreview("")
    }
  }, [props.isOpen])

  const handleSubmit = () => {
    let err = {}
    Object.keys(data).map((key) => {
      if (_.isEmpty(data[key]) && key !== "image_file_name") {
        err[key] = "Please do not leave this empty!"
      }
    })
    setError(err)
    if (_.isEmpty(err)) {
      props.createPromotion(data)
    }
  }

  const getBase64 = (file) => {
    let baseURL = ""
    let reader = new FileReader()
    // Convert the file to base64 text
    reader.readAsDataURL(file)
    reader.onload = () => {
      baseURL = reader.result
      setData({ ...data, image: baseURL, image_file_name: file.name })
    }
  }

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>New Promotion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Promotion Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter promotion name"
            value={data.promotion_title}
            onChange={(e) =>
              setData({ ...data, promotion_title: e.target.value })
            }
          />
          {error.promotion_title && (
            <Form.Text>{error.promotion_title}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Promotion Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter promotion description"
            value={data.promotion_description}
            onChange={(e) =>
              setData({ ...data, promotion_description: e.target.value })
            }
          />
          {error.promotion_description && (
            <Form.Text>{error.promotion_description}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter promotion category"
            value={data.category}
            onChange={(e) => setData({ ...data, category: e.target.value })}
          />
          {error.category && <Form.Text>{error.category}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Promotion Status</Form.Label>
          <Form.Select
            value={data.status}
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </Form.Select>
          {error.status && <Form.Text>{error.status}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label style={{ display: "block" }}>Promotion Image</Form.Label>
          {preview && (
            <img
              style={{ marginBottom: "10px", maxWidth: "200px" }}
              src={preview}
              alt="preview"
            />
          )}
          <Form.Control
            type="file"
            onChange={(e) => {
              setPreview(URL.createObjectURL(e.target.files[0]))
              getBase64(e.target.files[0])
            }}
          />
          {error.image && <Form.Text>{error.image}</Form.Text>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button variant="outline-primary" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Create

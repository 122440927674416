import { Button } from "react-bootstrap"
import {
  IoBrushOutline,
  IoTrashOutline
} from "react-icons/io5"

export const header = [
  {
    Header: "Banner Title",
    accessor: "banner_title",
    width: "20%"
  },
  {
    Header: "Banner Description",
    accessor: "banner_description",
    width: "40%"
  },
  {
    Header: "Status",
    width: "20%",
    Cell: (props) => {
      let temp = props.cell.row.original.status.toUpperCase()
      if( temp === 'ACTIVE' ){
        return(
          <p style={{ color: '#42ba96' }}>{ temp }</p>
        )
      }else{
        return(
          <p style={{ color: 'red' }}>{ temp }</p>
        )
      }
    }
  },
  {
    Header: "Action",
    Cell: (props) => (
      <>
        <Button
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedMobileBanner(props.cell.row.original)
            props.setOpen(true)
          }}
        >
          <IoBrushOutline />
        </Button>
        <Button
          variant="danger"
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedMobileBanner(props.cell.row.original)
            props.setConfirmationText(
              "Are you sure on deleting this Mobile Banner? This action cannot be undone."
            )
            props.setConfirmationModalOpen(true)
          }}
        >
          <IoTrashOutline />
        </Button>
      </>
    ),
  },
]

import React, { Component } from 'react'
import _ from 'lodash'

import { Get, Post } from 'utils/axios'
import CustomSnackbar from 'components/Snackbar';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      sort: '',
      loading: false,
      retailers: [],
      branches: [],
      distributors: [],
      admins: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeClientHOC = ( val, context ) => this.setState({ [ context ]: val})

    getDistibutor = () => Get(
      `/getDistributor`,
      this.getDistibutorSuccess,
      this.getDistibutorError,
      this.load
    )
    getDistibutorSuccess = payload => this.setState({ distributors: payload.data })
    getDistibutorError = error => this.requestError( error )

    createDistributor = ( dataToSubmit ) => Post(
      `/newUser`,
      dataToSubmit,
      this.createDistributorSuccess,
      this.createDistributorError,
      this.load
    )
    createDistributorSuccess = () => {
      this.getDistibutor()
      this.getRetailers()
      this.getAdmins()
      this.getBranches()
      this.requestSuccess('New Client Created')
    }
    createDistributorError = error => this.requestError( error )

    updateDistributor = ( dataToSubmit ) => Post(
      `/updateUser`,
      dataToSubmit,
      this.updateDistributorSuccess,
      this.updateDistributorError,
      this.load
    )
    updateDistributorSuccess = () => {
      this.getDistibutor()
      this.getBranches()
      this.getRetailers()
      this.getAdmins()
      this.requestSuccess( 'Client Updated' )
    }
    updateDistributorError = error => this.requestError( error.message )

    deleteDistributor = ( id ) =>Post(
      `/deleteUser`,
      {
        uid: id
      },
      this.deleteDistributorSuccess,
      this.deleteDistributorError,
      this.load
    )
    deleteDistributorSuccess = () => {
      this.getDistibutor()
      this.requestSuccess( 'User deleted' )
    }
    deleteDistributorError = error => this.requestError( error.message )

    getRetailers = () => Get(
      `/getRetailers`,
      this.getRetailersSuccess,
      this.getRetailersError,
      this.load
    )
    getRetailersSuccess = payload => this.setState({ retailers: payload.data })
    getRetailersError = error => this.requestError( error )

    getBranches = () => Get(
      `/getBranches`,
      this.getBranchesSuccess,
      this.getBranchesError,
      this.load
    )
    getBranchesSuccess = payload => this.setState({ branches: payload.data })
    getBranchesError = error => this.requestError( error.message )

    getAdmins = () => Get(
      `/getAdmins`,
      this.getAdminsSuccess,
      this.getAdminsFail,
      this.load
    )
    getAdminsSuccess = payload => this.setState({ admins: payload.data })
    getAdminsFail = error => this.requestError( error.message )

    assignDownline = ( user_id, downlineList ) =>{
      let temp = _.map( downlineList, downline => { return downline.uid } )

      Post(
       `/setDownline`,
      {
         user_id: user_id,
         downline: temp
       },
       this.assignDownlineSuccess,
       this.assignDownlineError,
       this.load
     )
    }
    assignDownlineSuccess = () =>{
      this.getDistibutor()
      this.requestSuccess( 'Downline Assigned' )
    }
    assignDownlineError = error => this.requestError( error.message )

    sortBy = ( accessor, list ) => {
      let temp = []
      switch( list ){
        case 'Distributor':
          temp = _.cloneDeep( this.state.distributors )
          break;
        case 'Retailer':
          temp = _.cloneDeep( this.state.retailers )
          break;
        case 'Branch':
          temp = _.cloneDeep( this.state.branches )
          break;
        case 'Admin':
          temp = _.cloneDeep( this.state.admins )
          break;
        default:
          temp = []
      }
      if( this.state.sort ){
        temp = temp.sort(( a, b ) => {
          if(a[ accessor ] < b[ accessor ]) { return -1; }
          if(a[ accessor ] > b[ accessor ]) { return 1; }
        })
        this.setState({ sort: false })
      } else {
        temp = temp.sort(( a, b ) => {
          if(a[ accessor ] < b[ accessor ]) { return 1; }
          if(a[ accessor ] > b[ accessor ]) { return -1; }
        })
        this.setState({ sort: true })
      }
      this.setState({ [ `${ list.toLowerCase() }s` ]: temp })
    }
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoadDistributor={ this.state.loading }
            distributors={ this.state.distributors }
            branches={ this.state.branches }
            retailers={ this.state.retailers }
            admins={ this.state.admins }

            sortBy={ this.sortBy }
            updateDistributor={ this.updateDistributor }
            onChangeClientHOC={ this.onChangeClientHOC }
            getDistibutor={ this.getDistibutor }
            getAdmins={ this.getAdmins }
            createDistributor={ this.createDistributor }
            assignDownline={ this.assignDownline }
            deleteDistributor={ this.deleteDistributor }
            getRetailers={ this.getRetailers }
            getBranches={ this.getBranches }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
import React from 'react';
import { MdDashboard, MdPeople } from 'react-icons/md'
import { FaBoxOpen } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5"
import { BsArrowLeftRight } from "react-icons/bs"
import { BiStoreAlt } from "react-icons/bi"

export const SidebarItem = [
  {
    title: 'Home',
    path:'/dashboard',
    icon: <MdDashboard/>,
  },
  {
    title: 'User Management',
    path:'/dashboard/distributor',
    icon: <MdPeople/>,
  },
  {
    title: 'Product Management',
    path:'/dashboard/stock',
    icon: <BiStoreAlt/>,
  },
  {
    title: 'Material Management',
    path:'/dashboard/material',
    icon: <FaBoxOpen/>,
  },
  {
    title: 'Purchase Order Management',
    path: '/dashboard/order',
    icon: <BsArrowLeftRight />
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: <IoSettingsOutline />
  }
]
import { Button } from "react-bootstrap"
import {
  IoBrushOutline,
  IoTrashOutline
} from "react-icons/io5"

export const header = [
  {
    Header: "Voucher Name",
    accessor: "voucher_name",
    width: "15%"
  },
  {
    Header: "Voucher Description",
    accessor: "voucher_description",
    width: "20%"
  },
  {
    Header: "Voucher Code",
    accessor: "voucher_code",
    width: "10%",
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>{props.value}</div>
    )
  },
  {
    Header: "Discount Type",
    accessor: "discount_type",
    width: "5%",
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex", textTransform: 'capitalize'}}>{props.value}</div>
    )
  },
  {
    Header: "Discount Amount",
    accessor: "discount_amount",
    width: "5%",
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>{props.value}</div>
    )
  },
  {
    Header: "Minimum Spend",
    accessor: "min_spend",
    width: "5%",
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>{props.value}</div>
    )
  },
  {
    Header: "Discount Cap",
    accessor: "discount_cap",
    width: "5%",
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>{props.value}</div>
    )
  },
  {
    Header: "Status",
    width: "10%",
    Cell: (props) => {
      let temp = props.cell.row.original.status.toUpperCase()
      if( temp === 'ACTIVE' ){
        return(
          <div style={{ color: '#42ba96', justifyContent:"center", display: "flex", fontWeight: "bold" }}>{ temp }</div>
        )
      }else{
        return(
          <div style={{ color: 'red', justifyContent:"center", display: "flex", fontWeight: "bold" }}>{ temp }</div>
        )
      }
    }
  },
  {
    Header: "Action",
    width:"15%",
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>
        <Button
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedVoucher(props.cell.row.original)
            props.setOpen(true)
          }}
        >
          <IoBrushOutline />
        </Button>
        <Button
          variant="danger"
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedVoucher(props.cell.row.original)
            props.setConfirmationText(
              "Are you sure on deleting this Voucher? This action cannot be undone."
            )
            props.setConfirmationModalOpen(true)
          }}
        >
          <IoTrashOutline />
        </Button>
      </div>
    ),
  },
]

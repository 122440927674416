import React from "react"
import { Line } from "react-chartjs-2"

const data = {
  labels: ["May", "Jun", "Jul", "Aug", "Sept", "Oct"],
  datasets: [
    {
      label: "Sales",
      data: [165, 159, 180, 181, 156, 155],
      fill: false,
      borderColor: "rgb(79, 144, 255)",
      tension: 0.1,
    },
    {
      label: "Order",
      data: [35, 70, 75, 40, 30, 21],
      fill: false,
      borderColor: "rgb(255, 255, 0)",
      tension: 0.1,
    }
  ],
}

const MonthlySales = () => {
  return (
    <div className="card p-3">
      <div className="d-flex align-items-center">
        <div className="card-title">Monthly Sales</div>
        <div style={{ fontWeight: "500", fontSize: "28px", marginLeft: 'auto' }}>RM 15273.00</div>
      </div>
      <Line
        data={data}
        options={{
          layout: {
            padding: "2",
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                color: "#fff",
              },
              grid: {
                color: "#5d30a1",
              },
            },
            y: {
              ticks: {
                color: "#fff",
              },
              grid: {
                color: "#5d30a1",
              },
            },
          },
        }}
      />
    </div>
  )
}
export default MonthlySales

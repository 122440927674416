import { 
  BrowserRouter as Router,
  Redirect,
  Route, 
  Switch 
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Main from 'container/Main'
import Login from 'container/Login';
import Register from 'container/Register';

const App = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path='/login' component={Login} initialPath />
          <Route path='/dashboard' component={Main} />
          <Route exact path='/register' component={Register} />
          <Redirect from={"/"} to={"/login"} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import firebase from 'firebase/compat/app';

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
     firebase.initializeApp({
        apiKey: "AIzaSyA13FYM8iuXzuejMTevFRb0NAjkmUqtgxs",
        authDomain: "distributor-system-bc214.firebaseapp.com",
        projectId: "distributor-system-bc214",
        storageBucket: "distributor-system-bc214.appspot.com",
        messagingSenderId: "219152519416",
        appId: "1:219152519416:web:47490a89816732f30ceb0d"
    })
  }
}
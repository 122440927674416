import React, { Component } from "react"
import _ from "lodash"

import CustomInput from "components/Input"
import localStorage from 'localStorage'
import CustomButton from "components/Button"
import LoadingModal from "components/LoadingModal"
import Logo from "assets/logo.png"
import LoginHOC from './action'

import "./index.scss"

class Login extends Component {

  state = {
    showForgetPassword: false
  }

  componentDidMount = () => {
    let user = localStorage.getItem('userLogin')
    if( user ) 
      this.props.history.push('/dashboard')
  }

  onChangeLogin = (val, context) => {
    let tmp = _.cloneDeep(this.props.loginCredential)
    tmp[context] = val
    this.props.onChangeLoginHOC(tmp, "loginCredential")
  }

  checkNull = () => _.values(this.props.loginCredential).some((x) => !x)
  render = () => {
    return (
      <div className="app d-flex">
        <div style={{ textAlign: 'center', margin: 'auto' }}>
          <img style={{ width: '50%' }} src={ Logo } alt={ 'logo' }/>
          <div style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            {
              !this.props.showForgetPassword && (
                <div className="card p-3">
                  <CustomInput
                    containerClass={"mt-5 d-flex justify-content-center"}
                    className={"at-login__input"}
                    placeholder="Email"
                    value={this.props.loginCredential.email || null }
                    type={"text"}
                    onChange={(val) => this.onChangeLogin(val, "email")}
                  />
                  <CustomInput
                    containerClass={"mt-2 d-flex justify-content-center"}
                    className={"at-login__input"}
                    placeholder="Password"
                    value={this.props.loginCredential.password || null}
                    type={"password"}
                    onChange={(val) => this.onChangeLogin(val, "password")}
                  />
                  <CustomButton
                    disabled={this.checkNull()}
                    containerClass={"mt-5 d-flex justify-content-center"}
                    className={"at-login__login-button"}
                    content={"Login"}
                    onClick={() => this.props.userLogin()}
                  />
                  <CustomButton
                    className={ "at-login__forget-password-button" }
                    content={"Forget Password"}
                    onClick={() => this.props.onChangeLoginHOC( true, 'showForgetPassword' )}
                  />
                </div>
              )
            }
            {
              this.props.showForgetPassword && (
                <div className="card p-3">
                  <h4>Forget Password</h4>
                  <CustomInput
                    containerClass={"mt-5 d-flex justify-content-center"}
                    className={"at-login__input"}
                    placeholder="Email"
                    value={ this.props.loginCredential.email || null }
                    type={"text"}
                    onChange={(val) => this.onChangeLogin(val, "email")}
                  />
                  <CustomButton
                    disabled={ !this.props.loginCredential.email }
                    containerClass={"mt-5 d-flex justify-content-center"}
                    style={{ fontSize: 14 }}
                    className={"at-login__login-button"}
                    content={"Recover Password"}
                    onClick={() => this.props.recoverPassword() }
                  />
                </div>
              )
            }
            {this.props.onLoginLoad && <LoadingModal />}
          </div>
        </div>
      </div>
    )
  }
}

export default LoginHOC(Login)

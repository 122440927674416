import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Typeahead } from 'react-bootstrap-typeahead';
import { CardContent } from '@mui/material';
import { Form, Modal, Button, Card } from 'react-bootstrap'
import { RiArrowUpDownFill } from 'react-icons/ri'

import 'react-bootstrap-typeahead/css/Typeahead.css';

const Assign = ( props ) => {

  const [ sort, setSort ] = useState( '' )
  const [ downlineList, setDownlineList ] = useState( [] )
  const [ existingDownline, setExistingDownline ] = useState( [] )

  useEffect( () => {
    if( props.selectedDistributor.downline_id && props.selectedDistributor.downline_id.length > 0 ){
      let temp = _.map( props.selectedDistributor.downline_id, downline => {
        return _.find( props.data, user => user.uid === downline )
      })
      setExistingDownline( temp )
    } else {
      setExistingDownline( [] )
    }

    setDownlineList( [] )

  }, [ props.selectedDistributor ])

  const nameSorting = () => {
    let temp = _.cloneDeep( existingDownline )
    if( sort ){
      temp = temp.sort(( a, b ) => {
        if(a.username < b.username) { return -1; }
        if(a.username > b.username) { return 1; }
      })
      setSort( false )
    } else {
      temp = temp.sort(( a, b ) => {
        if(a.username < b.username) { return 1; }
        if(a.username > b.username) { return -1; }
      })
      setSort( true )
    }
    setExistingDownline( temp )
  }

  return(
    <Modal show={props.isOpen} onHide={()=>props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Downline</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group style={{ marginBottom: 20 }}>
          <Form.Label>Downline</Form.Label>
          <Typeahead
            multiple
            id='assign-superior'
            labelKey="username"
            onChange={setDownlineList}
            options={props.data}
            placeholder="Enter all downline name"
            selected={downlineList}
          />
        </Form.Group>
        {
          existingDownline.length > 0 && (
            <>
              <Form.Label>
                Existing Downline 
                <RiArrowUpDownFill 
                  style={{ width: 18, height: 18, cursor: 'pointer', marginLeft: 10 }}
                  onClick={ () => nameSorting() }
                />
              </Form.Label>
              {
                existingDownline.map( downline => (
                  <Card>
                    <CardContent>
                      <h5>{ downline.username }</h5>
                      <p>Email: { downline.email }</p>
                      <p className='m-0'>Mobile No.: { downline.whatsapp }</p>
                    </CardContent>
                  </Card>
                ))
              }
            </>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={()=>props.setOpen(false)}>Close</Button>
        <Button 
          variant="outline-primary"
          onClick={()=> {
            props.assignDownline( props.selectedDistributor.uid, downlineList )
            props.setOpen(false)
          }}>
            Assign
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Assign;
import React, { useState, useEffect } from "react"
import _ from 'lodash'
import { Form, Button } from "react-bootstrap"

const fieldData = [
  { label: 'Introduction', value: 'introduction' },
  { label: 'Concept Design', value: 'concept_design' },
  { label: 'Concept Design - 01', value: 'concept_design_01' },
  { label: 'Concept Design - 02', value: 'concept_design_02' },
  { label: 'About Us', value: 'about_us' },
  { label: 'Mission', value: 'mission' },
  { label: 'Vision', value: 'vision' },
]

const Overview = ( props ) => {

  const [ overviewData, setOverviewData ] = useState({})

  useEffect( () => {
    setOverviewData( props.overview )
  }, [ props.overview ])

  const onChangeOverviewData = ( val, context ) => {
    let temp = _.cloneDeep( overviewData )
    temp[ context ] = val
    setOverviewData( temp )
  }

  return(
    <div className="card mx-3 mt-0" style={{ boxShadow: 'none', border: '1px solid #5d30a1' }}>
      <div className="row m-lg-4 m-3">
        {
          fieldData.map(( item, index ) => (
            <Form.Group key={ index } className={ 'col-md-6 px-2' }>
              <Form.Label style={{ fontSize: 25 }}>{ item.label }</Form.Label>
              <Form.Control
                as="textarea"
                className={ 'mb-2' }
                style={{ height: 200 }}
                placeholder="Enter the content here."
                value={overviewData[ item.value ]}
                onChange={(e) => onChangeOverviewData( e.target.value, item.value )
                }
              />
              <Button 
                variant="primary"
                style={{ float: 'right' }}
                disabled={ overviewData[ item.value ] === props.overview[ item.value ] }
                onClick={ () => {
                  props.updateOverview({
                    section: item.value,
                    content: overviewData[ item.value ]
                  })
                }}>
                Update
              </Button>
            </Form.Group>
          ))
        }
      </div>
    </div>
  )
}

export default Overview;
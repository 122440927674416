import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import localStorage from 'localStorage'

import Navbar from "components/navbar"
import UserInfo from "components/user"
import Dashboard from "./Dashboard"
import Stock from "./Stock"
import Order from "./Order"
import Distributors from "./Distributors"
import Statistic from "./Statistic"
import Settings from "./Settings"
import Material from "./Material"
class Main extends Component {

  componentDidMount = () => {
    let user = localStorage.getItem('userLogin')
    if( !user ) 
      this.props.history.push('/login')
  }

  render = () => {
    return (
      <div style={{ height: "100%" }}>
        <UserInfo />
        <Navbar />
        <div style={{ marginLeft: "100px" }}>
          <Switch>
            <Route path="/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/dashboard/distributor" exact>
              <Distributors />
            </Route>
            <Route path="/dashboard/stock" exact>
              <Stock />
            </Route>
            <Route path="/dashboard/material" exact>
              <Material />
            </Route>
            <Route path="/dashboard/order" exact>
              <Order />
            </Route>
            <Route path="/dashboard/statistic" exact>
              <Statistic />
            </Route>
            <Route path="/dashboard/settings" exact>
              <Settings />
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

export default Main

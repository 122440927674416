import React, { useEffect, useState } from "react"
import { Form, Modal, Button, ListGroup, Card } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import _ from "lodash"

import "react-bootstrap-typeahead/css/Typeahead.css"

import { createField } from "./assets"

const Create = (props) => {
  const [data, setData] = useState({
    order_by: [],
    items: [],
    total_price: 0,
    order_date: "",
    delivery_address: "",
  })
  const [err, setErr] = useState({})

  useEffect(() => {
    let sum = _.sumBy(data.items, (e) => {
      return typeof e.dist_price === "string"
        ? Number( e.dist_price ) * e.quantity
        : e.dist_price * e.quantity
    })
    if (sum !== data.total_price) {
      onChangeField(sum, "total_price")
    }
  }, [data.items])

  useEffect(() => {
    if (props.isOpen) {
      setData({
        order_by: [],
        items: [],
        total_price: 0,
        order_date: "",
        delivery_address: "",
      })
      setErr({})
    }
  }, [props.isOpen])

  const createOrder = () => {
    let tmpErr = {}
    if (data.order_by.length === 0) {
      tmpErr.order_by = "Please select the corresponding retailer"
    }
    if (data.items.length === 0) {
      tmpErr.items = "Please select at least one product"
    }
    if (data.order_by.length > 0 && data.order_by[0].role === "Branch" && data.total_price < 15000 ) {
      tmpErr.total_price = "Please make sure minimum purchase of Branch is RM15,000"
    }
    if (data.order_by.length > 0 && data.total_price < 300 ) {
      tmpErr.total_price = "Please make sure minimum purchase is RM300"
    }
    setErr(tmpErr)
    if (_.isEmpty(tmpErr)) {
      let tmp = _.cloneDeep(data)
      tmp.items.map((item, index) => {
        item.item_id = item.product_id
        item.product = item.product_name
        item.src = item.image_url[0]
        delete item.product_id
        delete item.product_name
        delete item.image_url
      })
      tmp.order_by = data.order_by[0].uid
      tmp.delivery_address = data.order_by[0].address
      tmp.order_date = new Date().toISOString()
      props.createPendingPayment(tmp)
      props.setOpen(false)
    }
  }

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(data)
    temp[context] = val
    setData(temp)
  }

  const addProduct = (val) => {
    let tmp = _.cloneDeep(data)
    let tmpValue = _.cloneDeep(val[0])
    tmpValue.quantity = 1
    tmp.items.push(tmpValue)
    setData(tmp)
  }

  const removeProduct = (val) => {
    let tmp = _.cloneDeep(data)
    let tmpIndex = _.findIndex(tmp.items, { product_id: val })
    tmp.items.splice(tmpIndex, 1)
    setData(tmp)
  }

  const changeQuantity = (item, val) => {
    if (val !== "0") {
      let tmp = _.cloneDeep(data)
      let tmpIndex = _.findIndex(tmp.items, { product_id: item.product_id })
      tmp.items[tmpIndex].quantity = _.isEmpty(val) ? val : parseInt(val)
      setData(tmp)
    } else {
      removeProduct(item.product_id)
    }
  }

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createField.map((item) => {
          if (item.type === "select") {
            return (
              <Form.Group className="mt-3">
                <Form.Label>{item.label}</Form.Label>
                <Form.Select
                  aria-label={item.placeholder}
                  value={data[item.value]}
                  onChange={(e) => onChangeField(e.target.value, item.value)}
                >
                  {item.options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )
          } else if (item.type === "typeahead") {
            return (
              <Form.Group>
                <Form.Label>{item.title}</Form.Label>
                <Typeahead
                  clearButton={item.clearButton}
                  multiple={item.multiple}
                  id={item.id}
                  labelKey={item.labelKey}
                  onChange={(value) => {
                    if (item.value === "items") {
                      addProduct(value)
                    } else {
                      onChangeField(value, item.value)
                    }
                  }}
                  options={
                    props[item.propsName]
                      ? item.propsName === "stock"
                        ? _.differenceBy(props.stock, data.items, "product_id")
                        : props[item.propsName]
                      : []
                  }
                  placeholder={item.placeholder}
                  selected={item.value === "items" ? [] : data[item.value]}
                />
                {err[item.value] && (
                  <Form.Label style={{ color: "#f00", fontSize: "12px" }}>
                    {err[item.value]}
                  </Form.Label>
                )}
              </Form.Group>
            )
          } else if (item.type === "product-list") {
            return (
              <ListGroup>
                {data[item.target].map((value) => {
                  return (
                    <ListGroup.Item>
                      <div className="row">
                        <div className="col-sm-3">
                          <img
                            style={{
                              width: "100%",
                              objectFit: "contain",
                            }}
                            src={value.image_url}
                            alt={value.product_name}
                          />
                        </div>
                        <div className="col-sm-8">
                          <div className="row m-2 h-100">
                            <div className="col-6">
                              <h5>{value.product_name}</h5>
                              <p>
                                RM {Number(value.dist_price).toFixed(2)}
                                <span style={{ fontSize: "12px" }}> /item</span>
                              </p>
                            </div>
                            <div className="col-3 px-3 m-auto">
                              <Form.Control
                                type="number"
                                min={0}
                                numbersOnly
                                value={value.quantity}
                                onChange={(e) =>
                                  changeQuantity(value, e.target.value)
                                }
                              />
                            </div>
                            <div className="col-3 px-3 m-auto">
                              RM{" "}
                              {(value.dist_price * value.quantity).toFixed(2)}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-1 m-auto">
                          <button
                            className="close btn-close rbt-close"
                            onClick={() => removeProduct(value.product_id)}
                          />
                        </div>
                      </div>
                    </ListGroup.Item>
                  )
                })}
              </ListGroup>
            )
          } else {
            return (
              <Form.Group>
                <Form.Label>{item.label}</Form.Label>
                <Form.Control
                  disabled={item.disabled}
                  type={item.type}
                  value={
                    item.toFixed
                      ? data[item.value].toFixed(item.toFixed)
                      : data[item.value]
                  }
                  placeholder={item.placeholder}
                  onChange={(e) => onChangeField(e.target.value, item.value)}
                />
                {err[item.value] && (
                  <Form.Label style={{ color: "#f00", fontSize: "12px" }}>
                    {err[item.value]}
                  </Form.Label>
                )}
              </Form.Group>
            )
          }
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button variant="outline-primary" onClick={() => createOrder()}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Create

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { compose, withProps } from "recompose"

import { Form, Modal, Button } from 'react-bootstrap'
import { updateField } from './assets'
import Geocode from "react-geocode";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import PlacesAutocomplete from 'react-places-autocomplete';

const MapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB8dM4k-FgfCTqIBiQRAk7CE9uXkxbYkMc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px`, marginTop: 10, marginBottom: 10 }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: 5 }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ location }) =>
  <GoogleMap
    defaultZoom={7}
    defaultCenter={{ lat: 4.2105, lng: 101.9758 }}>
    {
      !_.isEmpty( location ) && (
        <Marker position={{ lat: location.latitude, lng: location.longitude }} />
      )
    }
  </GoogleMap>
)

const Update = (props) => {
  const [data, setData] = useState({})

  useEffect(() => {
    Geocode.setApiKey("AIzaSyB8dM4k-FgfCTqIBiQRAk7CE9uXkxbYkMc");
    Geocode.setLanguage("en");
    Geocode.setRegion("my");
  }, [])

  useEffect(()=>{
    setData(props.data)
  },[props.data])

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep(data)
    temp[ context ] = val
    setData( temp )
  }

  const checkNull = () => _.values(data).some( x => x === "" )

  const handleSelect = address => {
    data.address = address
    Geocode.fromAddress(address).then( response => {
      onChangeField({
        latitude: response.results[0].geometry.location.lat,
        longitude: response.results[0].geometry.location.lng
      }, 'location')
    })
  };

  return(
    <Modal show={props.isOpen} onHide={()=>props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Client</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "70vh", overflowY: 'auto' }}>
      {
          updateField.map( item => {
            if( item.type === 'select' ){
              return(
                <Form.Group className="mt-3">
                  <Form.Label>{ item.label }</Form.Label>
                  <Form.Select 
                    aria-label={ item.placeholder } 
                    value={ data[item.value] } 
                    onChange={ e => onChangeField( e.target.value, item.value )}>
                  {
                    item.options.map( option => (
                      <option value={ option.value }>{ option.label }</option>
                    ))
                  }
                  </Form.Select>
                </Form.Group>
              )
            } else if ( item.type === 'location' ) {
              return(
                <Form.Group>
                  <Form.Label>{ item.label }</Form.Label>
                  <PlacesAutocomplete
                      value={ data.address }
                      onChange={ val => onChangeField( val, 'address' )}
                      onSelect={ handleSelect }
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <Form.Control 
                            {...getInputProps({
                              placeholder: item.placeholder,
                              className: 'location-search-input',
                            })} />
                          {
                            ( suggestions.length > 0 || loading ) && (
                              <div className="at-distributor-create__location-box">
                                { loading && <div>Loading...</div>}
                                { suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            )
                          }
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <MapComponent location={ data.location } />
                </Form.Group>
              )
            } else {
              return(
                <Form.Group>
                  <Form.Label>{ item.label }</Form.Label>
                  <Form.Control 
                    type={ item.type }
                    value={ data[item.value] } 
                    placeholder={ item.placeholder } 
                    onChange={ e => onChangeField( e.target.value, item.value )}/>
                  { item.label === 'Re-Enter Password' && data.password !== data.password2 && (
                    <p className='at-distributor-create__password-unmatch'>Password Not Match</p>
                  )}
                </Form.Group>
              )
            }
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={()=>props.setOpen(false)}>Close</Button>
        <Button 
          variant="outline-primary"
          onClick={()=>{
            props.updateClient( data )
            props.setOpen(false)
          }}>
            Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Update

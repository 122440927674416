import React from 'react'
import Select from 'react-select';

const CustomSelect = ({
  className,
  containerClass,
  conatinerStyle,
  placeholder,
  isLoading,
  isClearable,
  isSearchable,
  onChange,
  options,
}) => {
  return(
    <div className={ `${ containerClass }` } style={{ width: '100%', ...conatinerStyle }}>
      <Select
        className={ `${ className }` }
        defaultValue={ placeholder }
        isLoading={ isLoading }
        isClearable={ isClearable }
        isSearchable={ isSearchable }
        options={ options }
        onChange={ e => onChange( e.value ) }
      />
    </div>
  )
}

export default CustomSelect;
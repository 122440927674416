import React from 'react'
import './title.css'

const Title = ({
  header,
  subheader,
  headerStyle
}) => {
  return (
    <div style={{
      padding: "20px 20px 5px 20px"
      }}>
      <div>
        <div>
          <div className="title" style={{ ...headerStyle }}>{header}</div>
          <p>{subheader}</p>
        </div>
      </div>

    </div>
  )
}

export default Title

import React, { useMemo, useState, useEffect } from "react"
import Title from "components/title/title"
import { Table, Pagination, PageItem, Button, Form } from "react-bootstrap"
import { useTable, usePagination, useFilters } from "react-table"
import { matchSorter } from "match-sorter"
import { RiArrowUpDownFill } from 'react-icons/ri'

import { header } from "./assets"
import Create from "./Create"
import Update from "./Update"
import OrderHOC from "./actions"
import Confirmation from "components/confirmation/Confirmation"
import LoadingModal from "components/LoadingModal"
import Tabs from "components/tab"

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <Form.Control
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

const Order = (props) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [confirmationText, setConfirmationText] = useState("")
  const [selectedOrder, setSelectedOrder] = useState({})
  const [lastView, setLastView] = useState( 0 )
  const [selectedItem, setSelectedItem] = useState("Paid Order")

  useEffect(() => {
    props.getProduct()
    props.getRetailers()
    props.getOrder()
    props.getPendingPayment()
  }, [])

  const data = selectedItem === "Paid Order" ? props.order : props.pendingPayment
  const columns = useMemo(() => header, [])

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: lastView, pageSize: 10 },
      filterTypes,
      defaultColumn,
    },
    useFilters,
    usePagination
  )

  const createDummyRow = () => {
    let dummyRows = []
    let dummyCol = []
    for (let i = 0; i < header.length; i++) {
      dummyCol.push(<td></td>)
    }
    for (let i = 0; i < 10 - page.length; i++)
      dummyRows.push(<tr style={{ height: "40px" }}>{dummyCol}</tr>)
    return dummyRows
  }

  useEffect( () => {
    setLastView( pageIndex )
  }, [ pageIndex ])

  return (
    <>
      <div
        className="card"
        style={{
          margin: "10px",
        }}
      >
        <div className="row w-100 align-items-center">
          <div className="col-md-9">
            <Title
              header="Purchase Order Management"
              subheader="Listings of all orders received in this system."
              icon="pe-7s-users"
            />
          </div>
          {/* <div className="col-md-3">
            <Button
              onClick={() => setCreateModalOpen(true)}
              className="btn-dark"
            >
              Add New Order
            </Button>
          </div> */}
        </div>
        <div style={{ padding: "10px" }}>
          <Tabs
            selections={["Paid Order", "Pay-Later Order"]}
            selectedItem={selectedItem}
            onSelect={ val => {
              props.onChangeOrderHOC( '', 'sort' )
              setLastView( 0 )
              setSelectedItem( val )
            }}
          />
          <div className="card px-3 mt-0">
            <div className="row w-100 align-items-center">
              <div className="col-md-9">
                <Title
                  headerStyle={{ fontSize: 30 }}
                  header={selectedItem}
                  subheader=""
                  icon="pe-7s-users"
                />
              </div>
              {selectedItem === "Pay-Later Order" && (
                <div className="col-md-3">
                  <Button
                    onClick={() => setCreateModalOpen(true)}
                    className="btn-dark"
                  >
                    Add New Order
                  </Button>
                </div>
              )}
            </div>
            <div className="table-container">
              <Table
                responsive="sm"
                striped
                bordered
                hover
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          style={{ ...column.style }}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                          {column.sort && <RiArrowUpDownFill 
                          style={{ width: 18, height: 18, cursor: 'pointer', marginLeft: 10 }}
                          onClick={ () => props.sortBy(  selectedItem ) }
                        />}
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{ verticalAlign: "middle" }}
                            >
                              {cell.render("Cell", {
                                setSelectedOrder: setSelectedOrder,
                                setCancelModalOpen: setCancelModalOpen,
                                setConfirmationModalOpen:
                                  setConfirmationModalOpen,
                                setConfirmationText: setConfirmationText,
                                setUpdateModalOpen: setUpdateModalOpen,
                                selectedItem: selectedItem,
                              })}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  {createDummyRow()}
                </tbody>
              </Table>
            </div>
          </div>
          {pageCount > 1 && (
            <Pagination className="justify-content-center">
              <Pagination.First
                disabled={!canPreviousPage}
                onClick={() => {
                  gotoPage(0)
                }}
              />
              <Pagination.Prev
                disabled={!canPreviousPage}
                onClick={() => {
                  previousPage()
                }}
              />
              {[...Array(Math.ceil(data.length / 10))].map((item, index) => {
                return (
                  <PageItem
                    key={index + 1}
                    active={index === pageIndex}
                    onClick={() => {
                      gotoPage(index)
                    }}
                  >
                    {index + 1}
                  </PageItem>
                )
              })}
              <Pagination.Next
                disabled={!canNextPage}
                onClick={() => {
                  nextPage()
                }}
              />
              <Pagination.Last
                disabled={!canNextPage}
                onClick={() => {
                  gotoPage(pageCount - 1)
                }}
              />
            </Pagination>
          )}
        </div>
      </div>
      <Create
        orderBy={props.orderBy}
        stock={props.stock}
        isOpen={isCreateModalOpen}
        setOpen={setCreateModalOpen}
        createPendingPayment={props.createPendingPayment}
      />
      <Update
        orderBy={props.orderBy}
        stock={props.stock}
        selectedOrder={selectedOrder}
        isOpen={isUpdateModalOpen}
        setOpen={setUpdateModalOpen}
        createPendingPayment={props.createPendingPayment}
      />
      <Confirmation
        confirmationText={confirmationText}
        isOpen={isConfirmationModalOpen}
        onClick={() => props.updateStatus(selectedOrder)}
        setOpen={setConfirmationModalOpen}
      />
      <Confirmation
        confirmationText={confirmationText}
        isOpen={isCancelModalOpen}
        onClick={() =>
          selectedItem === "Paid Order"
            ? props.updateStatus(selectedOrder, "Cancelled")
            : props.deletePendingPayment(selectedOrder.order_id)
        }
        setOpen={setCancelModalOpen}
      />
      {(props.distributorLoading ||
        props.productLoading ||
        props.orderLoading) && <LoadingModal />}
    </>
  )
}

export default OrderHOC(Order)

import React, { Component } from 'react'
import _ from 'lodash'

import { Get, Post } from 'utils/axios'
import CustomSnackbar from 'components/Snackbar';


const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      distributorLoading: false,
      productLoading: false,
      orderLoading: false,
      orderBy: [],
      stock: [],
      order: [],
      pendingPayment: []
    }

    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeOrderHOC = ( val, context ) => this.setState({ [ context ]: val})

    processStatus = ( status ) => {
      switch( status ){
        case 'Paid':
          return 'Order Confirmed'
        case 'Order Confirmed':
          return 'Preparing'
        case 'Preparing':
          return 'Delivering'
        case 'Delivering':
          return 'Delivered'
      }
    }

    getRetailers = () => Get(
      `/getRetailers`,
      this.getRetailersSuccess,
      this.getRetailersError,
      param => this.setState({ orderLoading: param })
    )
    getRetailersSuccess = payload => this.setState({ orderBy: payload.data }, () => this.getBranches() )
    getRetailersError = error => this.requestError( error )

    getBranches = () => Get(
      `/getBranches`,
      this.getBranchesSuccess,
      this.getBranchesError,
      param => this.setState({ orderLoading: param })
    )
    getBranchesSuccess = payload => {
      let temp = _.cloneDeep( this.state.orderBy )
      temp = temp.concat( payload.data )
      this.setState({ orderBy: temp })
    }
    getBranchesError = error => this.requestError( error.message )

    getProduct = () => Get(
      `/getProduct`,
      this.getProductSuccess,
      this.getProductError,
      param => this.setState({ productLoading: param })
    )
    getProductSuccess = payload => {
      this.getMaterial()
      this.setState({ stock: payload.data.list })
    }
    getProductError = error => this.requestError( error.message )

    getMaterial = () => Get(
      `/getMaterial`,
      this.getMaterialSuccess,
      this.getMaterialError,
      param => this.setState({ productLoading: param })
    )
    getMaterialSuccess = payload => {
      let temp = _.cloneDeep( this.state.stock )
      let material = payload.data.map( item => {
        return{
          ...item,
          product_type: 'material',
          product_id: item.material_id,
          product_name: item.material_name
        }
      })
      temp = temp.concat( material )
      this.setState({ stock: temp })
    }
    getMaterialError = error => this.requestError( error.message )

    getOrder = () => Get(
      `/getOrder`,
      this.getOrderSuccess,
      this.getOrderError,
      param => this.setState({ orderLoading: param })
    )
    getOrderSuccess = payload => {
      let temp = payload.data
      temp.sort(( a, b ) => (a.transaction_date > b.transaction_date) ? -1 : ((b.transaction_date > a.transaction_date) ? 1 : 0))
      this.setState({ order: temp })
    }
    getOrderError = error => this.requestError( error.message )

    getPendingPayment = () => Get(
      `/getPendingPayment`,
      this.getPendingPaymentSuccess,
      this.getPendingPaymentError,
      param => this.setState({ orderLoading: param })
    )
    getPendingPaymentSuccess = payload => {
      let temp = payload.data
      temp.sort(( a, b ) => (a.transaction_date > b.transaction_date) ? -1 : ((b.transaction_date > a.transaction_date) ? 1 : 0))
      temp = temp.map( item => {
        return{
          ...item,
          delivery_status: item.status
        }
      })
      this.setState({ pendingPayment: temp })
    }
    getPendingPaymentError = error => this.requestError( error.message )

    updateStatus = ( dataToSubmit, status ) => Post(
      `/updateOrderStatus`,
      {
        order_id: dataToSubmit.order_id,
        email: dataToSubmit.order_by.email,
        status: status ? status : this.processStatus( dataToSubmit.delivery_status )
      },
      this.updateStatusSuccess,
      this.updateStatusError,
      param => this.setState({ orderLoading: param })
    )
    updateStatusSuccess = payload => {
      this.getOrder()
      this.requestSuccess("Order Status Updated")
    }
    updateStatusError = error => this.requestError( error.message )

    createPendingPayment = ( data ) => Post(
      `/newPendingPayment`,
      data,
      this.createPendingPaymentSuccess,
      this.createPendingPaymentError,
      param => this.setState({ orderLoading: param })
    )
    createPendingPaymentSuccess = payload => {
      this.requestSuccess( "Order created successfully")
      this.getPendingPayment()
    }
    createPendingPaymentError = error => this.requestError( error.message )

    deletePendingPayment = ( id) => Get(
      `/deletePendingPayment?order_id=${id}`,
      this.deletePendingPaymentSuccess,
      this.deletePendingPaymentError,
      param => this.setState({ orderLoading: param })
    )
    deletePendingPaymentSuccess = payload => {
      this.requestSuccess( "Order deleted" )
      this.getPendingPayment()
    }
    deletePendingPaymentError = error => this.requestError( error.message )

    sortBy = ( selectedItem ) => {
      let temp = _.cloneDeep( this.state.order )
      let accessor = selectedItem === "Paid Order" ? "transaction_date" : "order_date"
      let context = selectedItem === "Paid Order" ? "order" : "pendingPayment"

      switch( selectedItem ){
        case 'Paid Order':
          temp = _.cloneDeep( this.state.order )
          break;
        case 'Pay-Later Order':
          temp = _.cloneDeep( this.state.pendingPayment )
          break;
        default:
          temp = []
      }

      if( this.state.sort ){
        temp = temp.sort(( a, b ) => {
          if(a[ accessor ] < b[ accessor ]) { return -1; }
          if(a[ accessor ] > b[ accessor ]) { return 1; }
        })
        this.setState({ sort: false })
      } else {
        temp = temp.sort(( a, b ) => {
          if(a[ accessor ] < b[ accessor ]) { return 1; }
          if(a[ accessor ] > b[ accessor ]) { return -1; }
        })
        this.setState({ sort: true })
      }
      this.setState({ [ context ]: temp })
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            productLoading={ this.state.productLoading}
            orderLoading={ this.state.orderLoading}
            orderBy={ this.state.orderBy }
            stock={ this.state.stock }
            order={ this.state.order }
            pendingPayment={ this.state.pendingPayment }

            sortBy={ this.sortBy }
            getProduct={ this.getProduct }
            getOrder={ this.getOrder }
            updateStatus={ this.updateStatus }
            getPendingPayment={ this.getPendingPayment}
            getRetailers={ this.getRetailers }
            onChangeOrderHOC={ this.onChangeOrderHOC }
            createPendingPayment={ this.createPendingPayment }
            deletePendingPayment={ this.deletePendingPayment }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
import React, { Component } from 'react'
import _ from 'lodash'

import { Get, Post } from 'utils/axios'
import CustomSnackbar from 'components/Snackbar';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      sort: '',
      loading: false,
      material: [],
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeMaterialHOC = ( val, context ) => this.setState({ [ context ]: val})

    getMaterial = () => Get(
      `/getMaterial`,
      this.getMaterialSuccess,
      this.getMaterialError,
      this.load
    )
    getMaterialSuccess = payload => this.setState({ material: payload.data })
    getMaterialError = error => this.requestError( error.message )

    createMaterial = ( dataToSubmit ) => Post(
      `/newMaterial`,
      dataToSubmit,
      this.createMaterialSuccess,
      this.createMaterialError,
      this.load
    )
    createMaterialSuccess = payload => {
      this.getMaterial()
      this.requestSuccess( 'New Material Created' )
    }
    createMaterialError = error => this.requestError( error.message )

    updateMaterial = ( dataToSubmit ) => Post(
      `/updateMaterial`,
      dataToSubmit,
      this.updateMaterialSuccess,
      this.updateMaterialError,
      this.load
    )
    updateMaterialSuccess = payload => {
      this.getMaterial()
      this.requestSuccess( 'Material Updated' )
    }
    updateMaterialError = error => this.requestError( error.message )

    deleteMaterial = ( dataToSubmit ) => Post(
      `/deleteMaterial`,
      dataToSubmit,
      this.deleteMaterialSucces,
      this.deleteMaterialError,
      this.load
    )
    deleteMaterialSucces= payload => {
      this.getMaterial()
      this.requestSuccess( 'Material Deleted' )
    }
    deleteMaterialError = error => this.requestError( error.message )

    sortBy = () => {
      let temp = _.cloneDeep( this.state.material )

      if( this.state.sort ){
        temp = temp.sort(( a, b ) => {
          if(a.material_name < b.material_name) { return -1; }
          if(a.material_name > b.material_name) { return 1; }
        })
        this.setState({ sort: false })
      } else {
        temp = temp.sort(( a, b ) => {
          if(a.material_name < b.material_name) { return 1; }
          if(a.material_name > b.material_name) { return -1; }
        })
        this.setState({ sort: true })
      }
      this.setState({ material: temp })
    }
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoadMaterial={ this.state.loading }
            material={ this.state.material }

            sortBy={ this.sortBy }
            onChangeMaterialHOC={ this.onChangeMaterialHOC }
            createMaterial={ this.createMaterial }
            getMaterial={ this.getMaterial }
            deleteMaterial={ this.deleteMaterial }
            updateMaterial={ this.updateMaterial }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
import React, { Component } from 'react'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Get } from 'utils/axios'
import localStorage from 'localStorage'
import CustomSnackbar from 'components/Snackbar';
const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      showForgetPassword: false,
      loginCredential: {
        email: '',
        password: '',
      },
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeLoginHOC = ( val, context ) => this.setState({ [ context ]: val})

    userLogin = () => {
      this.load( true )
      const auth = getAuth();
      let temp = this.state.loginCredential
      let user = null
      signInWithEmailAndPassword( auth, temp.email, temp.password)
        .then((userCredential) => {
          user = userCredential.user
          getUser( userCredential.user.uid )
        })
        .catch((error) => {
          this.requestError( error.message )
          this.load( false )
        });
      
      const  getUser = ( uid ) => Get(
        `/getUser?uid=${ uid }`,
        payload => {
          if( payload.data.role === 'Super Admin' || payload.data.role === 'Admin' ){
            localStorage.setItem( 'userLogin', JSON.stringify({
              ...user,
              role: payload.data.role
            }))
            this.requestSuccess( 'Login Success.' )
            this.props.history.push('/dashboard')
          }else{
            this.requestError( "You are not admin of Appetite." )
          }
        },
        error => this.requestError( error ),
        this.load
      )
    }

    recoverPassword = () => {
      this.load( true )
      const auth = getAuth();
      sendPasswordResetEmail( auth, this.state.loginCredential.email ).then(() => {
        this.load( false )
        window.alert( 'Please check your email to recover your password.' )
        this.setState({ showForgetPassword: false })
      }).catch( error  => {
        this.load(false)
        window.alert( error.message )
      })
    }
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoginLoad={ this.state.loading }
            loginCredential={ this.state.loginCredential }
            showForgetPassword={ this.state.showForgetPassword }
            onChangeLoginHOC={ this.onChangeLoginHOC }
            recoverPassword={ this.recoverPassword }
            userLogin={ this.userLogin }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}


export default HOC
import { Button } from "react-bootstrap"
import Moment from "moment"
import {
  IoCallOutline,
  IoCalendarSharp,
  IoTrashOutline,
  IoCloudDownload,
  IoPencilOutline
} from "react-icons/io5"
import { SelectColumnFilter } from "utils/selectorFilter"

const renderUpdateButton = (status) => {
  switch (status) {
    case "Paid":
      return "Confirm Order"
    case "Order Confirmed":
      return "Prepare Order"
    case "Preparing":
      return "Deliver Order"
    case "Delivering":
      return "Order Delivered"
  }
}

const renderStatus = (status) => {
  switch (status) {
    case "Paid":
      return "#3cc5ff"
    case "Order Confirmed":
      return "#ff80f3"
    case "Pending Payment":
      return "#ff80f3"
    case "Preparing":
      return "#3cb3ff"
    case "Delivering":
      return "#e6ff3c"
    case "Delivered":
      return "#2ffd27"
    case "Cancelled":
      return "red"
  }
}

export const header = [
  {
    Header: "Order By",
    style: { width: "20%" },
    Cell: (props) => (
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: 16, marginBottom: 2 }}>
          {props.cell.row.original.order_by.username}
        </p>
        <p style={{ fontSize: 14, marginBottom: 2 }}>
          <IoCallOutline /> {props.cell.row.original.order_by.whatsapp}
        </p>
        <p style={{ fontSize: 12 }}>
          <IoCalendarSharp />{" "}
          {Moment(new Date(props.selectedItem === "Paid Order" ? props.cell.row.original.transaction_date : props.cell.row.original.order_date)).format(
            "DD/MM/YYYY"
          )}
        </p>
      </div>
    ),
    sort: true
  },
  {
    Header: "Product Ordered",
    Cell: (props) => (
      <>
        {props.cell.row.original.items &&
          props.cell.row.original.items.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  width: 240,
                  border: "1px solid #5d30a0",
                  borderRadius: 5,
                  padding: 5,
                }}
              >
                <img
                  style={{ height: 50, marginRight: 10 }}
                  src={item.src}
                  alt={item.item_id}
                />
                <div>
                  <p
                    style={{ fontSize: 12, marginBottom: 5 }}
                  >{`${item.product} ${item.product_volume ? `(${item.product_volume})` : '' }`}</p>
                  <p style={{ fontSize: 12 }}>{`Quantity: ${item.quantity}`}</p>
                </div>
              </div>
            )
          })}
      </>
    ),
  },
  {
    Header: "Total Price",
    accessor: "total_price",
    Cell: (props) => <>RM {props.cell.row.original.total_price}</>,
  },
  {
    Header: "Delivery Address",
    accessor: "delivery_address",
  },
  {
    Header: "Status",
    style: { width: "10%" },
    Filter: SelectColumnFilter,
    filter: "includes",
    Cell: (props) => (
      <p
        style={{ color: renderStatus(props.cell.row.original.delivery_status) }}
      >
        {props.cell.row.original.delivery_status}
      </p>
    ),
    accessor: "delivery_status",
  },
  {
    Header: "Action",
    style: { width: "15%" },
    Cell: (props) => (
      <>
        {props.cell.row.original.delivery_status !== "Delivered" &&
          props.cell.row.original.delivery_status !== "Cancelled" && (
            <>
              {props.cell.row.original.invoice_url && (
                <Button
                  className="button-side-by-side"
                  onClick={() => {
                    window.open(props.cell.row.original.invoice_url, "_blank")
                  }}
                >
                  <IoCloudDownload />
                </Button>
              )}
              {props.selectedItem === "Paid Order" && (
                <Button
                  className="button-side-by-side"
                  onClick={() => {
                    props.setSelectedOrder(props.cell.row.original)
                    props.setConfirmationText(
                      `Are you sure to change the order status to "${renderUpdateButton(
                        props.cell.row.original.delivery_status
                      )}"? This action will directly reflect on retailer side.`
                    )
                    props.setConfirmationModalOpen(true)
                  }}
                >
                  {renderUpdateButton(props.cell.row.original.delivery_status)}
                </Button>
              )}
              {props.selectedItem === "Pay-Later Order" && (
                <Button
                  className="button-side-by-side"
                  onClick={() => {
                    props.setSelectedOrder(props.cell.row.original)
                    props.setUpdateModalOpen(true)
                  }}
                >
                  <IoPencilOutline />
                </Button>
              )}
              <Button
                variant="danger"
                className="button-side-by-side"
                onClick={() => {
                  props.setSelectedOrder(props.cell.row.original)
                  props.setConfirmationText(
                    "Are you sure to cancel this order?"
                  )
                  props.setCancelModalOpen(true)
                }}
              >
                <IoTrashOutline />
              </Button>
            </>
          )}
        {props.cell.row.original.delivery_status === "Delivered" && (
          <p>No action require for delivered order.</p>
        )}
        {props.cell.row.original.delivery_status === "Cancelled" && (
          <p>No action require for cancelled order.</p>
        )}
      </>
    ),
  },
]

export const createField = [
  {
    type: "typeahead",
    clearButton: true,
    title: "Order By",
    id: "order-by",
    labelKey: "username",
    value: "order_by",
    neededValue: "uid",
    propsName: "orderBy",
    placeholder: "Please select a retailer/branch",
  },
  {
    type: "typeahead",
    title: "Order Products",
    id: "ordered-products",
    labelKey: "product_name",
    value: "items",
    propsName: "stock",
    placeholder: "Please select products",
  },
  {
    type: "product-list",
    target: "items",
  },
  {
    type: "text",
    disabled: true,
    label: "Total Price (RM)",
    value: "total_price",
    placeholder: "Total Price",
    toFixed: 2,
  },
]

import React, { Component } from 'react'
import moment from "moment"

import { Get } from 'utils/axios'
import CustomSnackbar from 'components/Snackbar';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      distributorQuantity: {},
      onLoadDistributorSales: false,
      distributorSales: [],
      salesAnalysis: {},
      onLoadSalesAnalysis: false,
      visitorClick: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeOrderHOC = ( val, context ) => this.setState({ [ context ]: val})

    getDistributorLevelNumber = () => Get(
      `/getDistributorLevelNumber`,
      this.getDistributorLevelNumberSuccess,
      this.getDistributorLevelNumberError,
      this.load
    )
    getDistributorLevelNumberSuccess = payload => this.setState({ distributorQuantity: payload.data })
    getDistributorLevelNumberError = error => this.requestError( error )

    getDistributorSales = () => Get(
      `/getDistributorSales`,
      this.getDistributorSalesSuccess,
      this.getDistributorSalesError,
      param => this.setState({ onLoadDistributorSales: param})
    )
    getDistributorSalesSuccess = payload => this.setState({ distributorSales: payload.data })
    getDistributorSalesError = error => this.requestError( error.message )

    getSalesAnalysis = () => Get(
      `/getSalesAnalysis`,
      this.getSalesAnalysisSuccess,
      this.getSalesAnalysisError,
      param => this.setState({ onLoadSalesAnalysis: param })
    )
    getSalesAnalysisSuccess = payload => this.setState({ salesAnalysis: payload.data })
    getSalesAnalysisError = error => this.requestError( error.message )

    getVisitorClick = () => Get(
      `/getPublicVisit?date=${moment( new Date() ).format( "YYYY-MM-DD" )}`,
      this.getVisitorClickSuccess,
      this.getVisitorClickError,
      this.load
    )
    getVisitorClickSuccess = payload => this.setState({ visitorClick: payload.data })
    getVisitorClickError = error => this.requestError( error.message )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoadDistributor={ this.state.loading }
            onLoadDistributorSales={ this.state.onLoadDistributorSales }
            getDistributorLevelNumber={ this.getDistributorLevelNumber }
            getDistributorSales={ this.getDistributorSales }
            getSalesAnalysis={ this.getSalesAnalysis }
            getVisitorClick={ this.getVisitorClick }
            distributorQuantity={ this.state.distributorQuantity }
            distributorSales={ this.state.distributorSales }
            visitorClick= { this.state.visitorClick }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
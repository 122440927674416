import React, { Component } from 'react'
import _ from 'lodash'
import { Get, Post } from 'utils/axios'
import CustomSnackbar from 'components/Snackbar';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      sort: '',
      loading: false,
      categoryList: [],
      categoryFilter: '',
      stock: [],
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeStockHOC = ( val, context ) => this.setState({ [ context ]: val})

    getProduct = () => Get(
      `/getProduct`,
      this.getProductSuccess,
      this.getProductError,
      this.load
    )
    getProductSuccess = payload => {
      let tempData = _.cloneDeep( payload.data.list )
      tempData.sort(( a,b ) => {
        if( a.product_name < b.product_name ) { return -1; }
        if( a.product_name > b.product_name ) { return 1; }
        return 0;
      })
      let tempCategory = [{ value: '', label: 'All' }]
      payload.data.category.map( category => {
        tempCategory.push({ value: category, label: category })
      })

      this.setState({ 
        stock: tempData,
        categoryList: tempCategory
      })
    }
    getProductError = error => this.requestError( error.message )

    createProduct = ( dataToSubmit ) => Post(
      `/newProduct`,
      dataToSubmit,
      this.createProductSuccess,
      this.createProductError,
      this.load
    )
    createProductSuccess = payload => {
      this.getProduct()
      this.requestSuccess( 'New Product Created' )
    }
    createProductError = error => this.requestError( error.message )

    updateProduct = ( dataToSubmit ) => Post(
      `/updateProduct`,
      dataToSubmit,
      this.updateProductSuccess,
      this.updateProductError,
      this.load
    )
    updateProductSuccess = payload => {
      this.getProduct()
      this.requestSuccess( 'Product Updated' )
    }
    updateProductError = error => this.requestError( error.message )

    deleteProduct = ( dataToSubmit ) => Post(
      `/deleteProduct`,
      dataToSubmit,
      this.deleteProductSucces,
      this.deleteProductError,
      this.load
    )
    deleteProductSucces= payload => {
      this.getProduct()
      this.requestSuccess( 'Product Deleted' )
    }
    deleteProductError = error => this.requestError( error.message )

    sortBy = () => {
      let temp = _.cloneDeep( this.state.stock )

      if( this.state.sort ){
        temp = temp.sort(( a, b ) => {
          if(a.product_name < b.product_name) { return -1; }
          if(a.product_name > b.product_name) { return 1; }
        })
        this.setState({ sort: false })
      } else {
        temp = temp.sort(( a, b ) => {
          if(a.product_name < b.product_name) { return 1; }
          if(a.product_name > b.product_name) { return -1; }
        })
        this.setState({ sort: true })
      }
      this.setState({ stock: temp })
    }
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoadStock={ this.state.loading }
            categoryList={ this.state.categoryList }
            categoryFilter={ this.state.categoryFilter }
            stock={ this.state.stock }

            sortBy={ this.sortBy }
            onChangeStockHOC={ this.onChangeStockHOC }
            createProduct={ this.createProduct }
            getProduct={ this.getProduct }
            deleteProduct={ this.deleteProduct }
            updateProduct={ this.updateProduct }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
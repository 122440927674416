import React, { useState } from "react"
import { ListGroup, ListGroupItem, Image, FormSelect } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import _ from "lodash"

const DistributorCard = (props) => {
  const [type, setType] = useState("month")

  return (
    <div className="right-container">
      <div className="distributor-container">
        <div className="card-title" style={{ fontSize: "24px" }}>
          Distributors
        </div>
        <div className="row align-items-center py-3">
          <div className="col-md-4">
            <div
              className="card-title"
              style={{ fontSize: "40px", textAlign: "center" }}
            >
              {props.distributorQuantity && props.distributorQuantity.leader
                ? props.distributorQuantity.leader
                : 0}
            </div>
            <div
              className="card-title"
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              Leader
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="card-title"
              style={{ fontSize: "40px", textAlign: "center" }}
            >
              {props.distributorQuantity &&
              props.distributorQuantity.super_leader
                ? props.distributorQuantity.super_leader
                : 0}
            </div>
            <div
              className="card-title"
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              Super Leader
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="card-title"
              style={{ fontSize: "40px", textAlign: "center" }}
            >
              {props.distributorQuantity && props.distributorQuantity.agent
                ? props.distributorQuantity.agent
                : 0}
            </div>
            <div
              className="card-title"
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              Agent
            </div>
          </div>
        </div>
        <div className="card-title" style={{ fontSize: "18px" }}>
          Performance
        </div>
        <FormSelect
          style={{
            backgroundColor: '#1c1e47',
            color: 'white'
          }}
          value={type}
          onChange={(e) => {
            setType(e.target.value)
          }}
        >
          <option value="day">Day</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </FormSelect>
        <div className="py-3">
          <ListGroup className="distributor-card-conatiner">
            {props.distributorSales &&
              props.distributorSales.length > 0 &&
              _.orderBy(
                props.distributorSales,
                (e) => {
                  return e.sales[type]
                },
                ["desc"]
              ).map((distributor) => {
                return (
                  <ListGroupItem className="list-group-items" action>
                    <div className="row align-items-center">
                      <div className="col-sm-2">
                        <div>
                          <Image
                            style={{ width: "50px", height: "50px", marginLeft: 10 }}
                            src="profile-avatar.png"
                            roundedCircle
                          />
                        </div>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-4">
                        <div style={{ fontWeight: "500", fontSize: "18px" }}>
                          {distributor.username}
                        </div>
                        <div style={{ fontWeight: "800", fontSize: "12px" }}>
                          {distributor.position}
                        </div>
                      </div>
                      <div className="col-sm-4" style={{ textAlign: "center" }}>
                        RM {distributor.sales[type].toFixed(2)}
                      </div>
                      {/* <div className="col-sm-1" style={{ textAlign: "center" }}>
                        <MdKeyboardArrowRight />
                      </div> */}
                    </div>
                  </ListGroupItem>
                )
              })}
            {/* <ListGroupItem
              className="list-group-items"
              action
              onClick={()=>history.push("/distributor")}
            >
              <div className="row align-items-center">
                <div className="col-sm-11" style={{ textAlign: "center", fontWeight: "900" }}>
                  MORE...
                </div>
                <div className="col-sm-1" style={{ textAlign: "center" }}>
                  <MdKeyboardArrowRight />
                </div>
              </div>
            </ListGroupItem> */}
          </ListGroup>
        </div>
      </div>
    </div>
  )
}

export default DistributorCard

import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Form, Modal, Button } from "react-bootstrap"

const Create = (props) => {
  const [data, setData] = useState({})
  const [error, setError] = useState({})

  useEffect(() => {
    if (props.isOpen) {
      setData({
        status: "active",
        voucher_description: "",
        voucher_name: "",
        voucher_code: "",
        discount_type: "percent",
        discount_amount: 0,
        discount_cap: 0,
        min_spend: 0,
      })
    }
  }, [props.isOpen])

  const handleSubmit = () => {
    let err = {}
    Object.keys(data).map((key) => {
      if (typeof data[key] !== "number") {
        if (_.isEmpty(data[key])) {
          err[key] = "Please do not leave this empty!"
        }
      }
    })
    setError(err)
    if (_.isEmpty(err)) {
      props.setOpen(false)
      props.createVouchers(data)
    }
  }

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>New Voucher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Voucher Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter voucher name"
            value={data.voucher_name}
            onChange={(e) => setData({ ...data, voucher_name: e.target.value })}
          />
          {error.voucher_name && <Form.Text>{error.voucher_name}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Voucher Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter voucher description"
            value={data.voucher_description}
            onChange={(e) =>
              setData({ ...data, voucher_description: e.target.value })
            }
          />
          {error.voucher_description && (
            <Form.Text>{error.voucher_description}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Voucher Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please enter voucher code"
            value={data.voucher_code}
            onChange={(e) => setData({ ...data, voucher_code: e.target.value })}
          />
          {error.voucher_code && <Form.Text>{error.voucher_code}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Discount Amount</Form.Label>
          <Form.Control
            type="number"
            min = "0"
            onWheel={(e) => e.target.blur()}
            placeholder="Please enter discount amount"
            value={data.discount_amount}
            onChange={(e) =>
              setData({ ...data, discount_amount: parseInt(e.target.value) })
            }
          />
          {error.discount_amount && (
            <Form.Text>{error.discount_amount}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Discount Cap</Form.Label>
          <Form.Control
            type="number"
            min = "0"
            onWheel={(e) => e.target.blur()}
            placeholder="Please enter discount cap of this voucher"
            value={data.discount_cap}
            onChange={(e) => setData({ ...data, discount_cap: parseInt(e.target.value) })}
          />
          {error.discount_cap && <Form.Text>{error.discount_cap}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Discount Type</Form.Label>
          <Form.Select
            value={data.discount_type}
            onChange={(e) =>
              setData({ ...data, discount_type: e.target.value })
            }
          >
            <option value="percent">Percent</option>
            <option value="amount">Amount</option>
          </Form.Select>
          {error.discount_type && <Form.Text>{error.discount_type}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Minimum spend</Form.Label>
          <Form.Control
            type="number"
            min = "0"
            onWheel={(e) => e.target.blur()}
            placeholder="Please enter minimum spend of this voucher"
            value={data.min_spend}
            onChange={(e) => setData({ ...data, min_spend: parseInt(e.target.value) })}
          />
          {error.min_spend && <Form.Text>{error.min_spend}</Form.Text>}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Voucher Status</Form.Label>
          <Form.Select
            value={data.status}
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Form.Select>
          {error.status && <Form.Text>{error.status}</Form.Text>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button variant="outline-primary" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Create

import { Modal, Button } from "react-bootstrap"

const Confirmation = (props) => {

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.confirmationText}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="outline-primary"
          onClick={() =>{
            if(props.onClick){
              props.onClick()
            }
            props.setOpen(false)
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Confirmation

import React, { useMemo, useState, useEffect } from "react"
import Title from "components/title/title"
import { Table, Pagination, PageItem, Button, Form } from "react-bootstrap"
import { useTable, usePagination, useFilters } from "react-table"
import CustomSelect from "components/Input/select"
import ImageViewer from "react-simple-image-viewer"
import { matchSorter } from "match-sorter"
import { RiArrowUpDownFill } from 'react-icons/ri'

import { header } from "./assets"
import Create from "./Create"
import Update from "./Update"
import Confirmation from "components/confirmation/Confirmation"
import LoadingModal from "components/LoadingModal"
import StockHOC from "./actions"

import "./index.scss"
import _ from "lodash"

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <Form.Control
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

const Stock = (props) => {
  const [data, setData] = useState( [] )
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [lastView, setLastView] = useState( 0 )
  const [selectedImage, setSelectedImage] = useState("")
  const [confirmationText, setConfirmationText] = useState("")
  const [selectedProduct, setSelectedProduct] = useState({})

  useEffect(() => {
    props.getProduct()
  }, [])

  useEffect(() => {
    setData( props.stock )
  }, [ props.stock ])

  useEffect(() => {
    setData( props.categoryFilter ? _.filter( props.stock, { category: props.categoryFilter }) : props.stock )
  }, [ props.categoryFilter ])
  
  const columns = useMemo(() => header, [])

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: lastView, pageSize: 10 },
      filterTypes,
      defaultColumn,
    },
    useFilters,
    usePagination
  )

  const createDummyRow = () => {
    let dummyRows = []
    let dummyCol = []
    for (let i = 0; i < header.length; i++) {
      dummyCol.push(<td></td>)
    }
    for (let i = 0; i < 10 - page.length; i++)
      dummyRows.push(<tr style={{ height: "40px" }}>{dummyCol}</tr>)
    return dummyRows
  }

  useEffect( () => {
    setLastView( pageIndex )
  }, [ pageIndex ])

  return (
    <div>
      <div
        className="card"
        style={{
          margin: "10px",
        }}
      >
        <div className="row w-100 align-items-center">
          <div className="col-md-9">
            <Title
              header="Product Management"
              subheader="Listings of all the stock products presence in the system."
              icon="pe-7s-users"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => setCreateModalOpen(true)}
              className="btn-dark"
            >
              Add New Products
            </Button>
          </div>
        </div>
        <div className="at-stock__category-filter">
          <label>Category Filter</label>
          <CustomSelect
            conatinerStyle={{ width:'auto' }}
            className={ 'at-stock__select' }
            defaultValue={ '' }
            isLoading={ props.onLoadStock }
            onChange={ val => props.onChangeStockHOC( val, 'categoryFilter' )}
            options={ props.categoryList }
          />
        </div>
        <div style={{ padding: "10px" }}>
          <div className="table-container">
            <Table striped bordered hover {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        style={{ ...column.style }}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                        {column.sort && <RiArrowUpDownFill 
                          style={{ width: 18, height: 18, cursor: 'pointer', marginLeft: 10 }}
                          onClick={ () => props.sortBy() }
                        />}
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ verticalAlign: "middle" }}
                          >
                            {cell.render("Cell", {
                              setSelectedImage: setSelectedImage,
                              setShowImage: setShowImage,
                              setOpen: setUpdateModalOpen,
                              setSelectedProduct: setSelectedProduct,
                              setConfirmationModalOpen:
                                setConfirmationModalOpen,
                              setConfirmationText: setConfirmationText,
                            })}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                {createDummyRow()}
              </tbody>
            </Table>
          </div>
          {pageCount > 1 && (
            <Pagination className="justify-content-center">
              <Pagination.First
                disabled={!canPreviousPage}
                onClick={() => {
                  gotoPage(0)
                }}
              />
              <Pagination.Prev
                disabled={!canPreviousPage}
                onClick={() => {
                  previousPage()
                }}
              />
              {[...Array(Math.ceil(data.length / 10))].map((item, index) => {
                return (
                  <PageItem
                    key={index + 1}
                    active={index === pageIndex}
                    onClick={() => {
                      gotoPage(index)
                    }}
                  >
                    {index + 1}
                  </PageItem>
                )
              })}
              <Pagination.Next
                disabled={!canNextPage}
                onClick={() => {
                  nextPage()
                }}
              />
              <Pagination.Last
                disabled={!canNextPage}
                onClick={() => {
                  gotoPage(pageCount - 1)
                }}
              />
            </Pagination>
          )}
        </div>
      </div>
      <Create
        isOpen={isCreateModalOpen}
        setOpen={setCreateModalOpen}
        createProduct={props.createProduct}
      />
      <Update
        product={selectedProduct}
        isOpen={isUpdateModalOpen}
        setOpen={setUpdateModalOpen}
        updateProduct={props.updateProduct}
      />
      <Confirmation
        confirmationText={confirmationText}
        onClick={() => props.deleteProduct(selectedProduct)}
        isOpen={isConfirmationModalOpen}
        setOpen={setConfirmationModalOpen}
      />
      {showImage && (
        <ImageViewer
          backgroundStyle={{
            width: "calc(100vw - 110px)",
            right: 0,
            left: "auto",
            zIndex: 100,
          }}
          src={selectedImage}
          onClose={() => setShowImage(false)}
        />
      )}
      {props.onLoadStock && <LoadingModal />}
    </div>
  )
}

export default StockHOC(Stock)

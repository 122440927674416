import React, { useState, useEffect } from "react"
import _ from "lodash"

import { Form, Modal, Button } from "react-bootstrap"
import { createField } from "./assets"
import { FaMinusCircle } from "react-icons/fa"

const Update = (props) => {
  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.material)
  }, [props.material])

  const onUploadImage = (event) => {
    let tempData = _.cloneDeep(data)
    if (event.target.files && event.target.files.length > 0) {
      let tmp = _.cloneDeep(data.image_url)
      let files = event.target.files
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader()
        reader.onload = (e) => {
          tmp.push({
            image: e.target.result,
            image_file_name: files[key].name,
          })
          setData({
            ...tempData,
            image_url: tmp,
          })
        }
        reader.readAsDataURL(event.target.files[key])
      })
    }
  }

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(data)
    temp[context] = val
    setData(temp)
  }

  const removeImage = (itemIndex) => {
    let tempData = _.cloneDeep(data)
    let tmp = _.cloneDeep(data.image_url)
    tmp = _.remove(tmp, (item, index) => {
      return index !== itemIndex
    })
    setData({
      ...tempData,
      image_url: tmp,
    })
  }

  return (
    <Modal
      show={props.isOpen}
      onHide={() => {
        setData(props.material)
        props.setOpen(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Materials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createField.map((item) => (
          <Form.Group>
            <Form.Label>{item.label}</Form.Label>
            {item.type === "select" ? (
              <Form.Group>
                <Form.Select
                  aria-label={item.placeholder}
                  value={data[item.value] ? data[item.value] : ""}
                  onChange={(e) => onChangeField(e.target.value, item.value)}
                >
                  {item.options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : item.label !== "Material Image" ? (
              <Form.Control
                type={item.type}
                value={data[item.value]}
                placeholder={item.placeholder}
                onChange={(e) => onChangeField(e.target.value, item.value)}
              />
            ) : (
              <>
                <div className="row  mx-2">
                  {data.image_url &&
                    data.image_url.length > 0 &&
                    data.image_url.map((item, index) => (
                      <div className="col-3" key={index}>
                        <FaMinusCircle
                          className="at-stock-create__image-remove"
                          onClick={() => removeImage(index)}
                        />
                        <img
                          className="at-stock-create__image-display"
                          src={item.image ? item.image : item}
                          alt={item.image_file_name}
                        />
                      </div>
                    ))}
                </div>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(event) => onUploadImage(event)}
                />
              </>
            )}
          </Form.Group>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            setData(props.material)
            props.setOpen(false)
          }}
        >
          Close
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            props.setOpen(false)
            props.updateMaterial(data)
          }}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Update

import React, { useState, useEffect } from "react"
import _ from 'lodash'
import { Table, Pagination, PageItem, Button, Form } from "react-bootstrap"
import { useTable, usePagination, useFilters } from "react-table"
import { matchSorter } from "match-sorter"
import { SelectColumnFilter } from "utils/selectorFilter"
import { RiArrowUpDownFill } from 'react-icons/ri'

import { header as dataHeader } from "./assets"
import Create from "./Create"
import Update from "./Update"
import Assign from "./Assign"
import Confirmation from "components/confirmation/Confirmation"
import Title from "components/title/title"
import localStorage from "localStorage"
import DistributorHOC from "./action"
import LoadingModal from "components/LoadingModal"
import Tabs from "components/tab"

import "./index.scss"

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <Form.Control
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

const Distributors = (props) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [isAssignModalOpen, setAssignModalOpen] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationText, setConfirmationText] = useState("")
  const [selectedDistributor, setSelectedDistributor] = useState({})
  const [selectedItem, setSelectedItem] = useState("Distributor")
  const [lastView, setLastView] = useState( 0 )
  const [columns, setColumns] = useState(dataHeader)
  const user = JSON.parse( localStorage.getItem("userLogin"))

  useEffect(() => {
    props.getAdmins()
    props.getDistibutor()
    props.getRetailers()
    props.getBranches()
  }, [])

  const data =
    selectedItem === "Admin"
      ? user.role === 'Super Admin'
        ? props.admins
        : _.filter( props.admins, { role: 'Admin' }) 
      : selectedItem === "Distributor"
        ? props.distributors
        : selectedItem === "Retailer"
          ? props.retailers
          : props.branches

  useEffect(() => {
    let temp = _.cloneDeep( dataHeader )
    setLastView( 0 )
    switch( selectedItem ){
      case 'Admin':
        if( user.role === 'Super Admin' ){
          temp[2] = {
            Header: "Role",
            accessor: "role",
            Filter: SelectColumnFilter,
            filter: "includes",
            style: { width: '10%' }
          }
          setColumns( temp )
          break;
        } else {
          temp.splice( 2, 1 )
          setColumns( temp )
          break;
        }
      case 'Retailer':
        temp.splice( 4, 0, {
          Header: "Ac. Pending Payment",
          accessor: "pending_payment",
          style: { width: '15%' },
          Cell: ({ cell }) => {
            if( cell.row.values.pending_payment && cell.row.values.pending_payment > 0 )
              return `RM ${ cell.row.values.pending_payment }`
            else
              return `RM 0`
          }
        })
        setColumns( temp )
        break;
      case 'Branch':
        temp.splice( 4, 0, {
          Header: "Ac. Pending Payment",
          accessor: "pending_payment",
          style: { width: '15%' },
          Cell: ({ cell }) => {
            if( cell.row.values.pending_payment && cell.row.values.pending_payment > 0 )
              return `RM ${ cell.row.values.pending_payment }`
            else
              return `RM 0`
          }
        })
        setColumns( temp )
        break;
      default:
        setColumns( temp )
        break;
    }
  }, [ selectedItem ])

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: lastView, pageSize: 10 },
      filterTypes,
      defaultColumn,
    },
    useFilters,
    usePagination
  )

  const createDummyRow = () => {
    let dummyRows = []
    let dummyCol = []
    for (let i = 0; i < dataHeader.length; i++) {
      dummyCol.push(<td></td>)
    }
    for (let i = 0; i < 10 - page.length; i++)
      dummyRows.push(<tr style={{ height: "40px" }}>{dummyCol}</tr>)
    return dummyRows
  }

  useEffect( () => {
    setLastView( pageIndex )
  }, [ pageIndex ])

  return (
    <>
      <div
        className="card"
        style={{
          margin: "10px",
        }}
      >
        <div className="row w-100 align-items-center">
          <div className="col-md-9">
            <Title header="User Management" subheader="" icon="pe-7s-users" />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => setCreateModalOpen(true)}
              className="btn-dark"
            >
              Add Clients
            </Button>
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <Tabs
            selections={["Distributor", "Retailer", "Branch", "Admin"]}
            selectedItem={selectedItem}
            onSelect={ val => {
              props.onChangeClientHOC( '', 'sort' )
              setSelectedItem( val )
            }}
          />
          <div className="card px-3 mt-0">
            <Title
              headerStyle={{ fontSize: 30 }}
              header={selectedItem}
              subheader={`Listings of all ${selectedItem} in this system.`}
              icon="pe-7s-users"
            />
            <div className="table-container">
              <Table striped bordered hover {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          style={{ ...column.style }}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                          {column.sort && <RiArrowUpDownFill 
                            style={{ width: 18, height: 18, cursor: 'pointer', marginLeft: 10 }}
                            onClick={ () => props.sortBy( column.filterProps, selectedItem ) }
                          />}
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{ verticalAlign: "middle" }}
                            >
                              {cell.render("Cell", {
                                setOpen: setUpdateModalOpen,
                                setAssignModalOpen: setAssignModalOpen,
                                setSelectedDistributor: setSelectedDistributor,
                                setConfirmationModalOpen:
                                  setConfirmationModalOpen,
                                setConfirmationText: setConfirmationText,
                                selectedItem: selectedItem,
                              })}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  {createDummyRow()}
                </tbody>
              </Table>
            </div>
            {pageCount > 1 && (
              <Pagination className="justify-content-center">
                <Pagination.First
                  disabled={!canPreviousPage}
                  onClick={() => {
                    gotoPage(0)
                  }}
                />
                <Pagination.Prev
                  disabled={!canPreviousPage}
                  onClick={() => {
                    previousPage()
                  }}
                />
                {[...Array(Math.ceil(data.length / 10))].map((item, index) => {
                  return (
                    <PageItem
                      key={index + 1}
                      active={index === pageIndex}
                      onClick={() => {
                        gotoPage(index)
                      }}
                    >
                      {index + 1}
                    </PageItem>
                  )
                })}
                <Pagination.Next
                  disabled={!canNextPage}
                  onClick={() => {
                    nextPage()
                  }}
                />
                <Pagination.Last
                  disabled={!canNextPage}
                  onClick={() => {
                    gotoPage(pageCount - 1)
                  }}
                />
              </Pagination>
            )}
          </div>
        </div>
      </div>
      <Create
        isOpen={isCreateModalOpen}
        setOpen={setCreateModalOpen}
        createClient={props.createDistributor}
      />
      <Update
        data={selectedDistributor}
        isOpen={isUpdateModalOpen}
        setOpen={setUpdateModalOpen}
        updateClient={props.updateDistributor}
      />
      <Assign
        data={data}
        isOpen={isAssignModalOpen}
        setOpen={setAssignModalOpen}
        assignDownline={props.assignDownline}
        selectedDistributor={ selectedDistributor }
      />
      <Confirmation
        confirmationText={confirmationText}
        isOpen={isConfirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        onClick={() => props.deleteDistributor(selectedDistributor.uid)}
      />
      {props.onLoadDistributor && <LoadingModal />}
    </>
  )
}

export default DistributorHOC(Distributors)

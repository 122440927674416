import React, { useState } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import { FaCaretRight } from "react-icons/fa";
import { SidebarItem } from './SidebarItem';
import Logo from 'assets/logo.png'

const Navbar = () => {
  const [sidebar, setSidebar] = useState(true)

  const showSidebar = () => setSidebar(!sidebar)
  return (
    <>
      <nav className={sidebar ? 'nav-menu' : 'nav-menu-expand'} >
        <ul className='nav-menu-item'>
          <div className='navbar' >
            <Link to='/' className='logo-bars' onClick={ () => setSidebar(true) }>
              <img style={{ width: 60, height: 60 }} src={ Logo } alt={ 'logo' } />
            </Link>
          </div>
          {SidebarItem.map((item, index) => {
            return (
              <li key={index} className="item-bars" >
                <Link to={item.path} className={sidebar?'nav-text nav-text-collapse':'nav-text nav-text-expand'}  onClick={ () => setSidebar(true) }>
                  <span style={{padding:"10px"}}>{item.icon}</span>
                  <span style={{ fontSize: 16 }} className={sidebar ? 'nav-item-text' : 'nav-item-text-expand'} > {item.title} </span>
                </Link>
              </li>
            )
          })
          } 
          </ul>
        <div className='nav-expand-btn' >
          <FaCaretRight onClick={showSidebar} className={sidebar ? 'expand-btn' : 'collapse-btn'} />
        </div>
      </nav >
    </>
  )
}

export default Navbar
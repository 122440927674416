import React from 'react'
import { Snackbar, IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Close } from '@material-ui/icons'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = ({
  open,
  message,
  onClick,
  type
}) => {

  return(
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={ open }
        autoHideDuration={ 6000 }
        onClose={ onClick }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={ onClick }>
            <Close />
          </IconButton>
        ]}>
          <Alert severity={ type } onClose={ onClick }>{ message }</Alert>
        </Snackbar>
  )
}

export default CustomSnackbar
import React from 'react'
import './index.scss'

const Tabs = ({
  selections,
  selectedItem,
  onSelect
}) => {

  return(
    <div style={{ paddingLeft: 40, marginTop: 10 }}>
      {
        selections.map( item => (
          <button 
            className={ item === selectedItem ? 'at-tab__selected' : 'at-tab__item' }
            onClick={ () => onSelect( item )}>
            { item }
          </button>
        ))
      }
    </div>
  )
}

export default Tabs;
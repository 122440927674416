import { Button } from "react-bootstrap"
import { IoBrushOutline, IoTrashOutline, IoImage } from "react-icons/io5"
import { SelectColumnFilter } from "utils/selectorFilter"

export const header = [
  {
    Header: "Product Name",
    accessor: "product_name",
    style: { width: "15%" },
    sort: true
  },
  {
    Header: "Description",
    accessor: "product_description",
    style: { width: "30%" },
  },
  {
    Header: "Distributor Price",
    accessor: "dist_price",
    style: { width: "8%" },
    Cell: ({ row }) => {
      let tmp = row.values.dist_price
      return (
        <span style={{ display: "flex", justifyContent: "center" }}>
          {parseFloat(tmp).toFixed(2)}
        </span>
      )
    },
  },
  {
    Header: "Retail Price",
    accessor: "retail_price",
    style: { width: "8%" },
    Cell: ({ row }) => {
      let tmp = row.values.retail_price
      return (
        <span style={{ display: "flex", justifyContent: "center" }}>
          {parseFloat(tmp).toFixed(2)}
        </span>
      )
    },
  },
  {
    Header: "Product Volume",
    accessor: "product_volume",
    style: { width: "8%" },
    Cell: ({ row }) => {
      let tmp = row.values.product_volume
      return (
        <span style={{ display: "flex", justifyContent: "center" }}>{tmp}</span>
      )
    },
  },
  {
    Header: "Stock Quantity",
    accessor: "stock",
    style: { width: "8%" },
    Cell: ({ row }) => {
      let tmp = row.values.stock
      return (
        <span style={{ display: "flex", justifyContent: "center" }}>{tmp}</span>
      )
    },
  },
  {
    Header: "Status",
    Filter: SelectColumnFilter,
    filter: "equals",
    accessor: "status",
    style: { width: "8%" },
    Cell: ({ row }) => {
      let status = row.values.status
      return (
        <span
          style={{
            color:
              status === "active" || status === undefined
                ? "#42ba96"
                : status === "revoked"
                ? "#df4759"
                : "	#ffc107",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {status === "active" || status === undefined ? "Active" : "Inactive"}
        </span>
      )
    },
  },
  {
    Header: "Action",
    style: { width: "15%" },
    Cell: (props) => (
      <div style={{justifyContent:"center", display: "flex"}}>
        <Button
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedImage(props.cell.row.original.image_url)
            props.setShowImage(true)
          }}
        >
          <IoImage />
        </Button>
        <Button
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedProduct(props.cell.row.original)
            props.setOpen(true)
          }}
        >
          <IoBrushOutline />
        </Button>
        <Button
          variant="danger"
          className="button-side-by-side"
          onClick={() => {
            props.setSelectedProduct(props.cell.row.original)
            props.setConfirmationText("Are you sure to delete this product?")
            props.setConfirmationModalOpen(true)
          }}
        >
          <IoTrashOutline />
        </Button>
      </div>
    ),
  },
]

export const createField = [
  {
    type: "text",
    label: "Product Name",
    placeholder: "Please enter the product name",
    value: "product_name",
  },
  {
    type: "text",
    label: "Product Description",
    placeholder: "Please enter the product decription",
    value: "product_description",
  },
  {
    type: "text",
    label: "Product Volume",
    placeholder: "Please enter the product volume",
    value: "product_volume",
  },
  {
    type: "text",
    label: "Category",
    placeholder: "Please enter the category name",
    value: "category",
  },
  {
    type: "text",
    label: "Retail Price",
    placeholder: "Please enter the product's retail price",
    value: "retail_price",
  },
  {
    type: "text",
    label: "Distributor Price",
    placeholder: "Please enter the product's distributor price",
    value: "dist_price",
  },
  {
    type: "number",
    label: "Stock",
    placeholder: "Please enter the product's stock",
    value: "stock",
  },
  {
    type: 'select',
    label: 'Status',
    placeholder: 'Status Selection',
    value: 'status',
    options: [
      { value: '', label: 'Please select a status' },
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ]
  },
  {
    label: "Product Image",
    value: "images",
  },
]

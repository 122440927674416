import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import { IoIosPeople } from "react-icons/io"
import { IoPeopleSharp, IoPerson } from "react-icons/io5"
import DailySales from "./DailySales"
import MonthlySales from "./MonthlySales"
import ProductSales from "./ProductSales"
import DistributorCard from "./DistributorCard"
import DistributorHOC from "./action"
import LoadingModal from "components/LoadingModal"
import "./dashboard.css"

const colorSet = ["#FFE400", "#FB798A", "#4EBBD6", "#B8BE00", "#F67F41"]

const Dashboard = (props) => {
  useEffect(() => {
    props.getDistributorLevelNumber()
    props.getDistributorSales()
    props.getVisitorClick()
  }, [])

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-xl-8">
          <div className="title" style={{ paddingLeft: 20 }}>
            HOME
          </div>
          <div className="row" style={{ width: "95%", margin: "auto" }}>
            <div className="col-md-6">
              <DailySales />
            </div>
            <div className="col-md-6 px-3">
              <div className="row dashboard-button-container">
                <div className="col-md-4 p-1">
                  <Button
                    className="dashboard-button card-title my-1"
                    style={{
                      width: "100%",
                      height: "100%",
                      textShadow:
                        "1px 0px rgba(255, 206, 86, 1), 0px 1px rgba(255, 206, 86, 1), -1px 0px rgba(255, 206, 86, 1), 0px -1px rgba(255, 206, 86, 1), 0px 0px 14px rgba(255, 206, 86, 1)",
                    }}
                  >
                    <div style={{ fontSize: "36px", lineHeight: "35px" }}>
                      18
                    </div>
                    <div>New Orders</div>
                  </Button>
                </div>
                <div className="col-md-4 p-1">
                  <Button
                    className="dashboard-button card-title my-1"
                    style={{
                      width: "100%",
                      height: "100%",
                      textShadow:
                        "1px 0px rgba(54, 162, 235, 1), 0px 1px rgba(54, 162, 235, 1), -1px 0px rgba(54, 162, 235, 1), 0px -1px rgba(54, 162, 235, 1), 0px 0px 14px rgba(54, 162, 235, 1)",
                    }}
                  >
                    Carousel Management
                  </Button>
                </div>
                <div className="col-md-4 p-1">
                  <Button
                    className="dashboard-button card-title my-1"
                    style={{
                      width: "100%",
                      height: "100%",
                      textShadow:
                        "1px 0px rgba(153, 102, 255, 1), 0px 1px rgba(153, 102, 255, 1), -1px 0px rgba(153, 102, 255, 1), 0px -1px rgba(153, 102, 255, 1), 0px 0px 14px rgba(153, 102, 255, 1)",
                    }}
                  >
                    Distributor Registration
                  </Button>
                </div>
              </div>
              <div className="row dashboard-button-container">
                <div className="col-md-6 p-1">
                  <Button
                    className="dashboard-button card-title my-1"
                    style={{
                      width: "100%",
                      height: "100%",
                      textShadow:
                        "1px 0px rgba(255, 159, 64, 1), 0px 1px rgba(255, 159, 64, 1), -1px 0px rgba(255, 159, 64, 1), 0px -1px rgba(255, 159, 64, 1), 0px 0px 14px rgba(255, 159, 64, 1)",
                    }}
                  >
                    Generate Report
                  </Button>
                </div>
                <div className="col-md-6 p-1">
                  <div
                    className="dashboard-button card-title my-1"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>Visitor Counter</div>
                    <div className="px-4">
                      <table className="w-100">
                        <tbody className="visitor-counter">
                          {Object.keys(props.visitorClick).map((key, index) => {
                            return (
                              <tr>
                                <td>
                                  {index < 2 ? (
                                    <IoPerson color={colorSet[index]} />
                                  ) : index < 4 ? (
                                    <IoPeopleSharp color={colorSet[index]} />
                                  ) : (
                                    <IoIosPeople color={colorSet[index]} />
                                  )}
                                </td>
                                <td
                                  style={{
                                    textShadow: `1px 0px ${colorSet[index]}, 0px 1px ${colorSet[index]}, -1px 0px ${colorSet[index]}, 0px -1px ${colorSet[index]}, 0px 0px 14px ${colorSet[index]}`,
                                  }}
                                >
                                  {key}
                                </td>
                                <td
                                  style={{
                                    textShadow: `1px 0px ${colorSet[index]}, 0px 1px ${colorSet[index]}, -1px 0px ${colorSet[index]}, 0px -1px ${colorSet[index]}, 0px 0px 14px ${colorSet[index]}`,
                                  }}
                                >
                                  {props.visitorClick[key]}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "95%", margin: "auto" }}>
            <div className="col-md-6">
              <MonthlySales />
            </div>
            <div className="col-md-6">
              <ProductSales />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <DistributorCard {...props} />
        </div>
      </div>
      {(props.onLoadDistributor || props.onLoadDistributorSales) && (
        <LoadingModal />
      )}
    </div>
  )
}

export default DistributorHOC(Dashboard)

import React, { useState } from 'react'
import _ from "lodash"
import { withRouter } from 'react-router-dom'

import CustomInput from "components/Input"
import CustomButton from "components/Button"
import LoadingModal from "components/LoadingModal"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import "./index.scss"

const Register = () => {

  const [ email, setEmail ] = useState( '' )
  const [ password, setPassword ] = useState( '' )
  const [ password2, setPassword2 ] = useState( '' )
  const [ error, setError ] = useState( '' )

  const createNewUser = () => {
    setError('')
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if( !emailRegex.test( email) )
      return setError( 'Invalid email format' )
    else if( password.length < 8 )
      return setError( 'Password must at least 8 character' )
    else if( password2 !== password )
      return setError( 'Password is not match' )
    else{
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
      })
      .catch((error) => {
        return setError( error.code + ': ' + error.message );
      });
    }
  }

  const checkNull = () =>  _.values({
    email: email,
    password: password
  }).some( x => !x )

  return(
    <>
      <CustomInput
        containerClass={ 'mt-2 d-flex justify-content-center' }
        placeholder='Email'
        value={ email }
        type={ 'text' }
        onChange={ val => setEmail( val )}
      />
      <CustomInput
        containerClass={ 'mt-2 d-flex justify-content-center' }
        placeholder='Password'
        value={ password }
        type={ 'password' }
        onChange={ val => setPassword( val )}
      />
      <CustomInput
        containerClass={ 'mt-2 d-flex justify-content-center' }
        placeholder='Re-Enter Password'
        value={ password2 }
        type={ 'password' }
        onChange={ val => setPassword2( val )}
      />
      
      <CustomButton
        disabled={ checkNull() }
        containerClass={ 'mt-2 d-flex justify-content-center' }
        content={ 'Register' }
        onClick={ () => createNewUser() }
      />
      <p className='error-message'>{ error }</p>
    </>
  )
}

export default Register
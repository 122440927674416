import React from 'react'

const CustomButton = ({
  className,
  style,
  containerClass,
  conatinerStyle,
  onClick,
  disabled,
  content
}) => {
  return (
    <div className={ `${ containerClass }` } style={{ width: '100%', ...conatinerStyle }}>
      <button
        disabled={ disabled }
        className={ `${ className }` }
        style={{ ...style }}
        onClick={ () => onClick()}>
        { content }
        </button>
    </div>
  )
}

export default CustomButton;
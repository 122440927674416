import React from "react"

import Title from "components/title/title"
import Barchart from "components/barchart"

const Statistic = () => {
  return (
    <>
      <div className="card">
        <div className="row align-items-center">
          <div className="col-md-9">
            <Title
              header="Statistic"
              subheader="View the statistic of your company over here"
              icon="pe-7s-users"
            />
          </div>
        </div>
        <div className="row d-flex justify-content-md-center">
          <div className="card col-md-8">
            <div className="row align-items-center">
              
            </div>
            <div className="row d-flex justify-content-md-center">
              <Barchart />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Statistic

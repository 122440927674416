import React, { Component } from "react"

import { Get, Post, Delete } from "utils/axios"
import CustomSnackbar from "components/Snackbar"
import LoadingModal from "components/LoadingModal"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      showSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
      onCloseSnackbar: () => {},

      loading: false,
      vouchers: [],
      promotion: [],
      publicBanners: [],
      mobileBanners: [],
      overview: {},
    }

    load = (param) => this.setState({ loading: param })
    requestError = (error) =>
      this.setState({
        showSnackbar: true,
        snackbarType: "error",
        onCloseSnackbar: () => this.setState({ showSnackbar: false }),
        snackbarMessage: error,
      })
    requestSuccess = (success, callback) =>
      this.setState({
        showSnackbar: true,
        snackbarType: "success",
        onCloseSnackbar: () => {
          this.setState({ showSnackbar: false })
          callback && callback()
        },
        snackbarMessage: success,
      })
    onChangeVouchersHOC = (val, context) => this.setState({ [context]: val })

    getVouchers = () =>
      Get(
        `/getVoucher`,
        this.getVouchersSuccess,
        this.getVouchersError,
        this.load
      )
    getVouchersSuccess = (payload) => this.setState({ vouchers: payload.data })
    getVouchersError = (error) => this.requestError(error.message)

    createVouchers = (data) =>
      Post(
        `/createVoucher`,
        data,
        this.createVouchersSuccess,
        this.createVouchersError,
        this.load
      )
    createVouchersSuccess = (payload) => {
      this.requestSuccess("Successfully Created")
      this.setState({ isCreateModalOpen: false })
      this.getVouchers()
    }
    createVouchersError = (error) => this.requestError(error.message)

    updateVoucher = (data) =>
      Post(
        `/updateVoucher`,
        data,
        this.updateVoucherSuccess,
        this.updateVoucherError,
        this.load
      )
    updateVoucherSuccess = (payload) => {
      this.requestSuccess(payload.data.message)
      this.setState({ isUpdateModalOpen: false })
      this.getVouchers()
    }
    updateVoucherError = (error) => this.requestError(error.message)

    deleteVouchers = (id) =>
      Delete(
        `/deleteVoucher?voucher_id=${id}`,
        this.deleteVouchersSuccess,
        this.deleteVouchersError,
        this.load
      )
    deleteVouchersSuccess = (payload) => {
      this.requestSuccess("Successfully Deleted")
      this.getVouchers()
    }
    deleteVouchersError = (error) => this.requestError(error.message)

    getPublicBanners = () =>
      Get(
        `/getPublicBanner`,
        this.getPublicBannersSuccess,
        this.getPublicBannersError,
        this.load
      )
    getPublicBannersSuccess = (payload) =>
      this.setState({ publicBanners: payload.data })
    getPublicBannersError = (error) => this.requestError(error)

    createPublicBanners = (data) =>
      Post(
        `/newPublicBanner`,
        data,
        this.createPublicBannersSuccess,
        this.createPublicBannersError,
        this.load
      )
    createPublicBannersSuccess = (payload) => {
      this.requestSuccess("Successfully Created")
      this.setState({ isCreateModalOpen: false })
      this.getPublicBanners()
    }
    createPublicBannersError = (error) => this.requestError(error)

    updatePublicBanner = (data) =>
      Post(
        `/updatePublicBanner`,
        data,
        this.updatePublicBannerSuccess,
        this.updatePublicBannerError,
        this.load
      )
    updatePublicBannerSuccess = (payload) => {
      this.requestSuccess(payload.data.message)
      this.setState({ isUpdateModalOpen: false })
      this.getPublicBanners()
    }

    deletePublicBanners = (id) =>
      Delete(
        `/deletePublicBanner?banner_id=${id}`,
        this.deletePublicBannersSuccess,
        this.deletePublicBannersError,
        this.load
      )
    deletePublicBannersSuccess = (payload) => {
      this.requestSuccess("Successfully Deleted")
      this.getPublicBanners()
    }
    deletePublicBannersError = (error) => this.requestError(error)

    getPromotion = () =>
      Get(
        `/getPromotion`,
        this.getPromotionSuccess,
        this.getPromotionError,
        this.load
      )
    getPromotionSuccess = (payload) =>
      this.setState({ promotion: payload.data })
    getPromotionError = (error) => this.requestError(error)

    createPromotion = (data) =>
      Post(
        `/newPromotion`,
        data,
        this.createPromotionSuccess,
        this.createPromotionError,
        this.load
      )
    createPromotionSuccess = (payload) => {
      this.requestSuccess("Successfully Created")
      this.setState({ isCreateModalOpen: false })
      this.getPromotion()
    }
    createPromotionError = (error) => this.requestError(error)

    updatePromotion = (data) =>
      Post(
        `/updatePromotion`,
        data,
        this.updatePromotionSuccess,
        this.updatePromotionError,
        this.load
      )
    updatePromotionSuccess = (payload) => {
      this.requestSuccess(payload.data.message)
      this.setState({ isUpdateModalOpen: false })
      this.getPromotion()
    }
    updatePromotionError = (error) => this.requestError(error)

    deletePromotion = (id) =>
      Delete(
        `/deletePromotion?promotion_id=${id}`,
        this.deletePromotionSuccess,
        this.deletePromotionError,
        this.load
      )
    deletePromotionSuccess = (payload) => {
      this.requestSuccess("Successfully Deleted")
      this.getPromotion()
    }
    deletePromotionError = (error) => this.requestError(error)

    getMobileBanners = () =>
      Get(
        `/getMobileBanner`,
        this.getMobileBannersSuccess,
        this.getMobileBannersError,
        this.load
      )
    getMobileBannersSuccess = (payload) =>
      this.setState({ mobileBanners: payload.data })
    getMobileBannersError = (error) => this.requestError(error)

    createMobileBanners = (data) =>
      Post(
        `/newMobileBanner`,
        data,
        this.createMobileBannersSuccess,
        this.createMobileBannersError,
        this.load
      )
    createMobileBannersSuccess = (payload) =>{
      this.requestSuccess("Successfully Created")
      this.setState({ isCreateModalOpen: false })
      this.getMobileBanners()
    }
    createMobileBannersError = (error) => this.requestError(error)

    updateMobileBanner = (data) => Post(
      `/updateMobileBanner`,
      data,
      this.updateMobileBannerSuccess,
      this.updateMobileBannerError,
      this.load
    )
    updateMobileBannerSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ isUpdateModalOpen: false })
      this.getMobileBanners()
    }
    updateMobileBannerError = (error) => this.requestError(error)

    deleteMobileBanners = (id) => Delete(
      `/deleteMobileBanner?banner_id=${id}`,
      this.deleteMobileBannersSuccess,
      this.deleteMobileBannersError,
      this.load
    )
    deleteMobileBannersSuccess = (payload) => {
      this.requestSuccess("Successfully Deleted")
      this.getMobileBanners()
    }
    deleteMobileBannersError = (error) => this.requestError(error)

    getOverview = () => Get(
      `/getOverview`,
      this.getOverviewSuccess,
      this.getOverviewError,
      this.load
    )
    getOverviewSuccess = payload => this.setState({ overview: payload.data })
    getOverviewError = (error) => this.requestError(error)

    updateOverview = dataToSubmit => Post(
      `/editOverview`,
      dataToSubmit,
      this.updateOverviewSuccess,
      this.updateOverviewError,
      this.load
    )
    updateOverviewSuccess = payload => {
      this.getOverview()
      this.requestSuccess( payload.data.message )
    }
    updateOverviewError = (error) => this.requestError(error)
    
    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadMobile={this.state.loading}
            vouchers={this.state.vouchers}
            overview={ this.state.overview }
            promotion={this.state.promotion}
            publicBanners={this.state.publicBanners}
            mobileBanners={this.state.mobileBanners}

            createVouchers={this.createVouchers}
            getVouchers={this.getVouchers}
            updateVoucher={this.updateVoucher}
            deleteVouchers={this.deleteVouchers}
            createPublicBanners={this.createPublicBanners}
            getPublicBanners={this.getPublicBanners}
            updatePublicBanner={this.updatePublicBanner}
            deletePublicBanners={this.deletePublicBanners}
            createPromotion={this.createPromotion}
            getPromotion={this.getPromotion}
            updatePromotion={this.updatePromotion}
            deletePromotion={this.deletePromotion}
            createMobileBanners={this.createMobileBanners}
            getMobileBanners={this.getMobileBanners}
            updateMobileBanner={ this.updateMobileBanner }
            deleteMobileBanners={this.deleteMobileBanners}
            getOverview={ this.getOverview }
            updateOverview={ this.updateOverview }
          />
          <CustomSnackbar
            open={this.state.showSnackbar}
            message={this.state.snackbarMessage}
            onClick={this.state.onCloseSnackbar}
            type={this.state.snackbarType}
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  return WithHOC
}

export default HOC

import React, { Component } from 'react'

import { Post } from 'utils/axios'
import CustomSnackbar from 'components/Snackbar';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    
    changeUserCredential = dataToSubmit => Post(
      `/changeUserCredential`,
      dataToSubmit,
      this.changeUserCredentialSuccess,
      this.changeUserCredentialError,
      this.load
    )
    changeUserCredentialSuccess = payload => {
      console.log( payload.data )
      this.requestSuccess( 'Email/Password Updated')
    }
    changeUserCredentialError = error => this.requestError( error.message )
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoading={ this.state.loading }

            changeUserCredential={ this.changeUserCredential }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
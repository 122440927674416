import React, { useState } from "react"
import _ from "lodash"

import { Form, Modal, Button } from "react-bootstrap"
import { createField } from "./assets"
import { FaMinusCircle } from "react-icons/fa"

const Create = (props) => {
  const [data, setData] = useState({
    product_name: "",
    product_description: "",
    product_volume: "",
    retail_price: "",
    dist_price: "",
    stock: "",
    images: [],
    image_url: [],
    status: "active",
  })

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(data)
    temp[context] = val
    setData(temp)
  }

  const checkNull = () => _.values(data).some((x) => x === "")

  const removeImage = (itemIndex) => {
    let temp = _.cloneDeep(data.images)
    temp = _.remove(temp, (item, index) => {
      return index !== itemIndex
    })
    onChangeField(temp, "images")
  }

  const onUploadImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let tmp = _.cloneDeep(data.images)
      let files = event.target.files
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader()
        reader.onload = (e) => {
          tmp.push({
            image: e.target.result,
            image_file_name: files[key].name,
          })
          onChangeField(tmp, "images")
        }
        reader.readAsDataURL(event.target.files[key])
      })
    }
  }

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createField.map((item) => (
          <Form.Group>
            <Form.Label>{item.label}</Form.Label>
            {item.type === "select" ? (
              <Form.Group>
                <Form.Select
                  aria-label={item.placeholder}
                  value={data[item.value] ? data[item.value] : ""}
                  onChange={(e) => onChangeField(e.target.value, item.value)}
                >
                  {item.options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : item.label !== "Product Image" ? (
              <Form.Control
                type={item.type}
                placeholder={item.placeholder}
                onChange={(e) => onChangeField(e.target.value, item.value)}
              />
            ) : (
              <>
                <div className="row  mx-2">
                  {data.images.length > 0 &&
                    data.images.map((item, index) => (
                      <div className="col-3" key={index}>
                        <FaMinusCircle
                          className="at-stock-create__image-remove"
                          onClick={() => removeImage(index)}
                        />
                        <img
                          className="at-stock-create__image-display"
                          src={item.image}
                          alt={item.image_file_name}
                        />
                      </div>
                    ))}
                </div>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(event) => onUploadImage(event)}
                />
              </>
            )}
          </Form.Group>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button
          // disabled={ checkNull() || data.images.length === 0 }
          variant="outline-primary"
          onClick={() => {
            props.createProduct(data)
            props.setOpen(false)
          }}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Create

import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import localStorage from 'localStorage'
import { MdLogout, MdOutlineLockOpen } from 'react-icons/md'
import ChangeCredential from './changeCredential'
import { signOut, getAuth } from "firebase/auth";
import LoadingModal from "../LoadingModal"
import UserInfoHOC from './actions'
import User from 'assets/user.png'

import './index.css'

const user = JSON.parse( localStorage.getItem("userLogin"))

const UserInfo = (props) => {

  const [showChangeCredential, setShowChangeCredential] = useState( false )

  return(
    <>
      <Dropdown>
        <Dropdown.Toggle className='user-button-container' variant="primary" id="dropdown-basic">
          <img src={ User } alt={ 'user' } />
        </Dropdown.Toggle>
      
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={ () => {
              setShowChangeCredential( true )
            }}
            ><MdOutlineLockOpen style={{ marginRight: 10 }} /> Change Email/Password</Dropdown.Item>
          <Dropdown.Item 
            href='/login'
            onClick={() => {
              let auth = getAuth()
              localStorage.removeItem('userLogin')
              signOut(auth)
          }}><MdLogout style={{ marginRight: 10 }}/> Sign Out </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ChangeCredential 
        isOpen={ showChangeCredential }
        setOpen={ setShowChangeCredential }
        changeUserCredential={ props.changeUserCredential }
        user={ user }
      />
      { props.onLoading && <LoadingModal />}
    </>
  )
}

export default UserInfoHOC( UserInfo );
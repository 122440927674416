import React, { useMemo, useState, useEffect } from "react"
import Title from "components/title/title"
import { Table, Pagination, PageItem, Button, Form } from "react-bootstrap"
import { useTable, usePagination, useFilters } from "react-table"
import { matchSorter } from "match-sorter"

import { header } from "./table/header"
import Create from "./Create"
import Update from "./Update"
import Confirmation from "components/confirmation/Confirmation"

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <Form.Control
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

const PublicBanner = (props) => {
  const [lastView, setLastView] = useState( 0 )
  const [confirmationText, setConfirmationText] = useState("")
  const [selectedPublicBanner, setSelectedPublicBanner] = useState({})
  const [ isCreateModalOpen, setCreateModalOpen ] = useState(false)
  const [ isConfirmationModalOpen, setConfirmationModalOpen ] = useState(false)
  const [ isUpdateModalOpen, setUpdateModalOpen ] = useState(false)

  const data = useMemo(() => props.publicBanners, [props.publicBanners])
  const columns = useMemo(() => header, [])

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: lastView, pageSize: 10 },
      filterTypes,
      defaultColumn,
    },
    useFilters,
    usePagination
  )

  const createDummyRow = () => {
    let dummyRows = []
    let dummyCol = []
    for (let i = 0; i < header.length; i++) {
      dummyCol.push(<td></td>)
    }
    for (let i = 0; i < 10 - page.length; i++)
      dummyRows.push(<tr style={{ height: "40px" }}>{dummyCol}</tr>)
    return dummyRows
  }

  useEffect( () => {
    setLastView( pageIndex )
  }, [ pageIndex ])

  return (
    <>
      <div className="card mx-3 mt-0" style={{ boxShadow: 'none', border: '1px solid #5d30a1' }} >
        <div className="row w-100 align-items-center">
          <div className="col-md-9">
            <Title
              header="Public Banners"
              subheader="Listings of all Public Banners received in this system."
              icon="pe-7s-users"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={ () => setCreateModalOpen(true)}
              className="btn-dark"
            >
              Add New Public Banners
            </Button>
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <div className="table-container">
            {props.publicBanners.length > 0 && <Table striped bordered hover {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} style={{ width: column.width }}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ verticalAlign: "middle", width: cell.width }}
                          >
                            {cell.render("Cell", {
                              setOpen: setUpdateModalOpen,
                              setSelectedPublicBanner: setSelectedPublicBanner,
                              setConfirmationModalOpen: setConfirmationModalOpen,
                              setConfirmationText: setConfirmationText,
                            })}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                {createDummyRow()}
              </tbody>
            </Table>}
          </div>
          {pageCount > 1 && (
            <Pagination className="justify-content-center">
              <Pagination.First
                disabled={!canPreviousPage}
                onClick={() => {
                  gotoPage(0)
                }}
              />
              <Pagination.Prev
                disabled={!canPreviousPage}
                onClick={() => {
                  previousPage()
                }}
              />
              {[...Array(Math.ceil(data.length / 10))].map((item, index) => {
                return (
                  <PageItem
                    key={index + 1}
                    active={index === pageIndex}
                    onClick={() => {
                      gotoPage(index)
                    }}
                  >
                    {index + 1}
                  </PageItem>
                )
              })}
              <Pagination.Next
                disabled={!canNextPage}
                onClick={() => {
                  nextPage()
                }}
              />
              <Pagination.Last
                disabled={!canNextPage}
                onClick={() => {
                  gotoPage(pageCount - 1)
                }}
              />
            </Pagination>
          )}
        </div>
      </div>
      <Create
        createPublicBanners={props.createPublicBanners}
        isOpen={isCreateModalOpen}
        setOpen={setCreateModalOpen}
      />
      <Update
        publicBanners={selectedPublicBanner}
        isOpen={isUpdateModalOpen}
        updatePublicBanner={props.updatePublicBanner}
        setOpen={setUpdateModalOpen}
      />
      <Confirmation
        selectedData={selectedPublicBanner}
        confirmationText={confirmationText}
        isOpen={isConfirmationModalOpen}
        onClick={()=>props.deletePublicBanners(selectedPublicBanner.banner_id)}
        setOpen={setConfirmationModalOpen}
      />
    </>
  )
}

export default PublicBanner
